import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';

//componentes
import LoadingLayer from 'components/loaders/LoadingLayer';
import PlanosToolBar from './planos/PlanosToolBar';
import SeatEditEvent from 'components/common/maps/SeatEditEvent';

//services
import * as eventsApi from 'api/eventsApi';

//helpers
import { colorToClass } from 'utils/helpers';

//formik

//drag and resize
import useDraggableScroll from 'use-draggable-scroll';
import { Rnd } from 'react-rnd';
import Selecto from 'react-selecto';

//notifications
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as notify from 'utils/notify';
import ReactTooltip from 'react-tooltip'

const EventDBPlanos = ({ eventID, eventDataSimple}) => {

	const MySwal = withReactContent(Swal);

	//genericos
	const [isLoading, setIsLoading] = useState(false);
	const [pageLoading, setPageLoading] = useState(true);
	const [mapData, setMapData] = useState({});
	const [roomList, setRoomList] = useState([]);
	const [currentRoom, setCurrentRoom] = useState(null);

	//toolbar
	const [currentZoom, setCurrentZoom] = useState(100);
	const [sectionsMenuOpen, setSectionsMenuOpen] = useState(false);
	const [asistMenuOpen, setAsistMenuOpen] = useState(false);

	const [cursorIsHand, setCursorIsHand] = useState(false);
	const [capacityData, setCapacityData] = useState({
		areas:[],
		mapaDentro: 0,
		mapaOcuppedSeats:0,
		mapaPorEntrar: 0,
		mapaSeatsInside: 0,
		mapaSeats: 0,
		sections:[]
	});

	const [selectedSection, setSelectedSection] = useState({
		type: null,
		value: null,
	});

	//------------------------ GENÉRICAS

	useEffect(() => {
		getRoomList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if(currentRoom && currentRoom.mapaId) {
			setCursorIsHand(false);
			setCurrentZoom(100);
			getMapDetails(currentRoom.mapaId);
			setSelectedSection({type: null,value: null});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentRoom]);

	const getRoomList = async () => {
		const data = await eventsApi.getEventRooms(parseInt(eventID));
		if (data && data.succeeded) {
			setRoomList(data.data);

			if (data.data.length > 0) {
				setCurrentRoom(data.data[0]);
			} else {
				notify.error('Ups! Este evento no tiene salas asignadas.');
			}
		}
	};

	const getMapDetails = async (idMapa, mainLoad = true) => {
		mainLoad && setPageLoading(true);
		const data = await eventsApi.getDetailMap({id: idMapa, eventoId: parseInt(eventID)});
		if (data && data.succeeded) {
			setMapData(data.data);
			setCapacityData(data.data?.capacity);
		} else {
			notify.error('Ups! Ha ocurrido un error cargando el mapa de la sala.');
		}
		mainLoad && setPageLoading(false);
	};

	//---------------- TOOLBAR FNs

	const refElems = useRef([]);
	const mapScrollRef = useRef(null);
	const { onMouseDown } = useDraggableScroll(mapScrollRef);
	const [flagSectionsTouched, setFlagSectionsTouched] = useState(false);

	const toggleCursor = () => {
		setCursorIsHand(!cursorIsHand);
	};

	const openAsistMenu = () => {
		setSelectedSection({type: null,value: null});
		sectionsMenuOpen && setSectionsMenuOpen(false);
		setAsistMenuOpen(!asistMenuOpen);
	};

	const openSectionsMenu = () => {
		setSelectedAsistente(null);
		setShowBulkModal(false);
		asistMenuOpen && setAsistMenuOpen(false);
		setSectionsMenuOpen(!sectionsMenuOpen);
	};

	const selectSection = (section) => {
		setSelectedSection(section);
	};

	//---------------- MAP FNs

	//actualización tipo de asiento (simple)
	const updateSeatSectionSingle = async (
		seat,
		areaIndex,
		rowIndex,
		seatIndex
	) => {
		if (seat && seat.id) {
			if(seat.asistente) {
				notify.error('No se puede actualizar la sección porque la butaca ya está asociada a un invitado.');
			} else {
				setIsLoading(true);
				const updatedValues = {
					id: seat.id,
					sectionId: selectedSection.value ? selectedSection.value : null,
					section: selectedSection.value ? selectedSection : null,
				};
	
				const data = await eventsApi.updateSectionSeats({mapaId:mapData.value, eventoId:parseInt(eventID), listSeats:[updatedValues]});
				if (data && data.succeeded && data.data.capacity && data.data.listSeats) {

					const newMap = (mapData.areas[areaIndex].rows[rowIndex].seats[seatIndex] = data.data.listSeats[0]);
					setMapData({ ...mapData, newMap });
					setCapacityData(data.data.capacity);

				} else {
					notify.error(
						`Ha ocurrido un error actualizando el asiento. ${data.message}`
					);
				}
				setFlagSectionsTouched(true);
				setIsLoading(false);
			}
		}
	};

	//actualización tipo de asiento (multi - drag)
	const updateSeatSectionMulti = async (seats) => {

		if(seats && seats.length > 0) {
			setIsLoading(true);
			const updatedSeats = seats.map((seat) => ({
				id: parseInt(seat.dataset.seatid),
				type:seat.dataset.seattype,
				sectionId: selectedSection.value ? selectedSection.value : null,
				section: selectedSection.value ? selectedSection : null,
				wAsistente: seat.dataset.asistente === "true" ? true : false,
				dataset:seat.dataset
			}));

			const noSpaces = updatedSeats.filter((s) => (s.type !== "space" && s.wAsistente === false));

			const data = await eventsApi.updateSectionSeats({mapaId:mapData.value, eventoId:parseInt(eventID), listSeats:noSpaces});
			if (data && data.succeeded && data.data.capacity && data.data.listSeats) {

				// eslint-disable-next-line array-callback-return
				const newSeat = noSpaces.map((seat, ixSeat) => {
					mapData.areas[seat.dataset.area].rows[seat.dataset.row].seats[
						seat.dataset.seat
					].type === 'seat' &&
						(mapData.areas[seat.dataset.area].rows[seat.dataset.row].seats[
							seat.dataset.seat
						] = data.data.listSeats[ixSeat]);
				});
				setMapData({ ...mapData, newSeat});
				setCapacityData(data.data.capacity);

			} else {
				notify.error(
					`Ha ocurrido un error actualizando el asiento. ${data.message}`
				);
			}
			setFlagSectionsTouched(true);
			setIsLoading(false);
		}
	};

	//---------------- SECCIONES FNs

	const handleUpdateSection = async (values, formProps) => {
		const updatedValues = {...values, color:{ claseCss: colorToClass(values.color) }, eventoMapaValue: mapData.value};
		const data = await eventsApi.setSection(updatedValues);
		if (data && data.succeeded) {
			formProps.resetForm({ values: {value: 0,label: '',color: ''} });
			setMapData({...mapData, secciones:data.data});
		} else {
			notify.error(
				`Ha ocurrido un error actualizando la sección. ${data.message}`
			);
		}
	};

	const handleDeleteSection = (sectionID) => {

		MySwal.fire({
			title: '¿Quieres eliminar la sección?',
			cancelButtonText: 'Conservar',
			showCancelButton: true,
			confirmButtonText: 'Eliminar',
			focusConfirm: false,
			focusCancel: false,
			reverseButtons: true,
			buttonsStyling: false,
			customClass: {
				confirmButton: 'btn-prim-dark btn',
				cancelButton: 'btn-prim-light btn',
			},
		}).then(async (result) => {
			if (result && result.isConfirmed) {
				const data = await eventsApi.deleteSection({eventoId: parseInt(eventID),sectionId:parseInt(sectionID)});
				if (data && data.succeeded) {
					setMapData({...mapData, secciones:data.data});
					notify.success('La sección se ha eliminado correctamente.');
				} else {
					notify.error('Ha ocurrido un error eliminando la sección.');
				}
			}
		});
	}

	//---------------- ASISTENTES FNs

	const [selectedAsistente, setSelectedAsistente] = useState(null);
	const [asistentesList, setAsistentesList] = useState([]);
	const [asistentesTotal, setAsistentesTotal] = useState(0);
	const [asistentesAsignados, setAsistentesAsignados] = useState(0);
	const [asistentesPorAsignar, setAsistentesPorAsignar] = useState(0);
	const [asistentesDentro, setAsistentesDentro] = useState(0);
	const [asistentesPorEntrar, setAsistentesPorEntrar] = useState(0);
	const [showBulkModal, setShowBulkModal] = useState(false);
	const [tmpAsistentesSelected, setTmpAsistentesSelected] = useState([]);

	const updateSittingMap = async (
		seat,
		areaIndex,
		rowIndex,
		seatIndex,
		double
	) => {

		if (seat && seat.section && seat.canSeat) {

			const canManageSeat = double
				? true
				: selectedAsistente.totalAsistentesConSittingSentados <
				  selectedAsistente.totalAsistentesConSitting
				? true
				: false;

			const occupiedSeat = double ? false : seat.asistente ? true : false;

			if(occupiedSeat) {
				notify.error('Ups! Este asiento ya está ocupado.');
			} else {
				if (canManageSeat) {
					setIsLoading(true);
					const updatedSeat = {
						eventoId: parseInt(eventID),
						invitadoId: double ? null : selectedAsistente.invitadoId,
						eventoMapaAsientoId: seat.id,
						mapaId: currentRoom.mapaId
					};
					const data = await eventsApi.UpdateAssistantSitting(updatedSeat);
					if (data && data.succeeded) {
	
						//actualizar el asiento en mapData
						const newMap = (mapData.areas[areaIndex].rows[rowIndex].seats[
							seatIndex
						] = data.data.seat);
						setMapData({ ...mapData, newMap });
	
						//actualizar las capacidades (manteniendo secciones que no da respuesta)
						const updatedAforo = {
							areas:data.data.capacity?.areas,
							mapaDentro: data.data.capacity?.mapaDentro,
							mapaOcuppedSeats:data.data.capacity?.mapaOcuppedSeats,
							mapaPorEntrar: data.data.capacity?.mapaPorEntrar,
							mapaSeatsInside: data.data.capacity?.mapaSeatsInside,
							mapaSeats: data.data.capacity?.mapaSeats,
							sections:capacityData.sections
						};
						setCapacityData(updatedAforo);
	
						//actualizar el asistente en listado (data.data.asistentes)
						const updatedID = parseInt(data.data.asistentes.invitadoId);
						const currentAsistentes = asistentesList;
						let areaEditedIndex = _.findIndex(currentAsistentes, {
							invitadoId: updatedID,
						});
						currentAsistentes.splice(areaEditedIndex, 1, data.data.asistentes);
						setAsistentesList(currentAsistentes);
						setAsistentesTotal(data.data.totalAsistentes);
						setAsistentesAsignados(data.data.totalAsistentesAsignados);
						setAsistentesPorAsignar(data.data.totalAsistentesPorAsignar);
						setAsistentesDentro(data.data.totalAsistentesDentro);
						setAsistentesPorEntrar(data.data.totalAsistentesPorEntrar);
	
						//actualizar el asistente seleccionado
						setSelectedAsistente(data.data.asistentes);
	
					} else {
						notify.error(
							`Ups! Ha ocurrido un error asignando el asiento. ${data.message}`
						);
					}
					setIsLoading(false);
				} else {
					notify.error('El invitado seleccionado no dispone de más asistentes con sitting pendientes de sentar.');
				}

			}



		} else {
			notify.error('El asiento seleccionado no tiene ninguna sección asignada.');
		}
	};

	const updateNoSittingMap = async (values, filters) => {

		setIsLoading(true);
		const updatedNoData = {
			noSitting: { salas: values },
			invitadoId: selectedAsistente.invitadoId,
			eventoId: parseInt(eventID),
			mapaId:mapData.value,
			filter:filters
		};

		const data = await eventsApi.updateAssistantsWithouthSitting(updatedNoData);
		if (data && data.succeeded) {

			//actualizar los asiento en mapData
			setMapData({ ...mapData, areas:data.data.areas });

			//actualizar las capacidades (manteniendo secciones que no da respuesta)
			const updatedAforo = {
				areas:data.data.capacity?.areas,
				mapaDentro: data.data.capacity?.mapaDentro,
				mapaOcuppedSeats:data.data.capacity?.mapaOcuppedSeats,
				mapaPorEntrar: data.data.capacity?.mapaPorEntrar,
				mapaSeats: data.data.capacity?.mapaSeats,
				mapaSeatsInside: data.data.capacity?.mapaSeatsInside,
				sections:capacityData.sections
			};
			setCapacityData(updatedAforo);

			setAsistentesList(data.data.asistentes);
			setAsistentesTotal(data.data.totalAsistentes);
			setAsistentesAsignados(data.data.totalAsistentesAsignados);
			setAsistentesPorAsignar(data.data.totalAsistentesPorAsignar);
			setAsistentesDentro(data.data.totalAsistentesDentro);
			setAsistentesPorEntrar(data.data.totalAsistentesPorEntrar);

			//busca el asistente seleccionado
			const currentAsistent = _.find(data.data.asistentes, {invitadoId: data.data.invitadoActualizadoId});
			setSelectedAsistente(currentAsistent);

		} else {
			notify.error(
				`Ups! Ha ocurrido un error actualizando los asientos. ${data.message}`
			);
		}
		setIsLoading(false);
	
	};

	const updateNoSittingBulk = async(bulkData) => {
		setIsLoading(true);
		const data = await eventsApi.updateBulkNoSitting(bulkData);
		if (data && data.succeeded) {
			getMapDetails(currentRoom.mapaId,false);
			setTmpAsistentesSelected([]);
			setSelectedAsistente(null);
			setShowBulkModal(false);
			//------------------
			setAsistentesList(data.data.asistentes);
			setAsistentesTotal(data.data.totalAsistentes);
			setAsistentesAsignados(data.data.totalAsistentesAsignados);
			setAsistentesPorAsignar(data.data.totalAsistentesPorAsignar);
			setAsistentesDentro(data.data.totalAsistentesDentro);
			setAsistentesPorEntrar(data.data.totalAsistentesPorEntrar);
		} else {
			notify.error(
				`Ups! Ha ocurrido un error asignando asientos. ${data.message}`
			);
		}
		setIsLoading(false);
	}

	//-----------------
	const handleMapDownload = async () => {
		setIsLoading(true);
		try {
			const url = `/eventos/downloadPlano/${mapData.value}/${eventID}`;
			const fileName = `Plano ${mapData.label}.xlsx`;
			await eventsApi.downloadExcelAuth(url, fileName);
		} catch (ex) {
			notify.error(
				`Ha ocurrido un error al descargar el plano. ${ex.message}`
			);
		}
		setIsLoading(false);
	};

	return pageLoading ? (
		<LoadingLayer visible={pageLoading} />
	) : (
		<>
		<div className={`plano-container ${showBulkModal ? 'is-bulk-open' : ''}`}>
			{isLoading && <LoadingLayer visible={isLoading} />}
			<PlanosToolBar
				selectedSection={selectedSection}
				selectedSectionFn={selectSection}
				mapData={mapData}
				capacityMap={capacityData}
				currentZoom={currentZoom}
				updateCurrentZoom={setCurrentZoom}
				cursorHand={cursorIsHand}
				toggleCursor={toggleCursor}
				asistMenuOpen={asistMenuOpen}
				asistMenuFn={openAsistMenu}
				sectionsMenuOpen={sectionsMenuOpen}
				sectionsMenuFn={openSectionsMenu}
				roomList={roomList}
				currentRoom={currentRoom}
				updateCurrentRoom={setCurrentRoom}
				updateSectionFn={handleUpdateSection}
				deleteSectionFn={handleDeleteSection}
				eventSiteName={eventDataSimple.eventSite}
				eventoPasado={eventDataSimple.eventoPasado}
				eventID={eventID}
				asistenteSelected={selectedAsistente}
				selectAsistente={setSelectedAsistente}
				asistentesList={asistentesList}
				setAsistentesList={setAsistentesList}
				asistentesTotal={asistentesTotal}
				setAsistentesTotal={setAsistentesTotal}
				asistentesAsignados={asistentesAsignados}
				setAsistentesAsignados={setAsistentesAsignados}
				asistentesPorAsignar={asistentesPorAsignar}
				setAsistentesPorAsignar={setAsistentesPorAsignar}
				asistentesDentro={asistentesDentro}
				setAsistentesDentro={setAsistentesDentro}
				asistentesPorEntrar={asistentesPorEntrar}
				setAsistentesPorEntrar={setAsistentesPorEntrar}
				updateNoSittingFn={updateNoSittingMap}
				tmpAsistentesSelected={tmpAsistentesSelected}
				setTmpAsistentesSelected={setTmpAsistentesSelected}
				showBulkModal={showBulkModal}
				setShowBulkModal={setShowBulkModal}
				updateBulkFn={updateNoSittingBulk}
				flagSectionsTouched={flagSectionsTouched}
				setFlagSectionsTouched={setFlagSectionsTouched}
				handleMapDownload={handleMapDownload}
			/>

			{mapData.areas && mapData.areas.length > 0 ? (
				<>
					<ReactTooltip id="tt-seat" effect="solid" html={true} />
					<div
						className={`map-scroll ${
							cursorIsHand ? 'is-dragging' : 'is-static'
						} ${selectedSection.type ? 'has-section' : 'no-section'} ${asistMenuOpen ? 'is-asist-open' : ''} ${selectedAsistente ? 'is-sitting' : ''}`}
						ref={mapScrollRef}
						onMouseDown={(e) => cursorIsHand && onMouseDown(e)}
					>
						<div className="map-canvas" style={{ transform: `scale(${currentZoom/100})${asistMenuOpen ? ' translateX(380px)':''}` }}>
							{mapData.areas.map((area, indexArea) => (
								<Rnd
									key={area.id}
									ref={(ref) => (refElems.current[indexArea] = ref)}
									className="area-elem"
									default={{
										x: area.pos ? area.pos.x : 0,
										y: area.pos ? area.pos.y : 0,
									}}
									size={{
										width: area.size ? area.size.w : 'auto',
										height: area.size ? area.size.h : 'auto',
									}}
									enableResizing={false}
									disableDragging={true}
								>
									<div className={`area-container idxcont-${indexArea} area-type-${area.type.type}`} style={{ transform: `rotate(${area.pos.z}deg)`, zIndex:1}}>
										<div className="cont-header">
											<div className="cont-name cont-planos">
												{area.type && area.type.type === 'seat' && (
													<>
														<div className="seat-name">{area.title}</div>
														<div className="seat-count">
															{capacityData?.areas[area.id]
																? capacityData?.areas[area.id].seats
																: 0}
														</div>
													</>
												)}
											</div>
											{area.type &&
												area.type.type === 'seat' &&
												capacityData?.areas[area.id] && (
													<div className="aforo">
														<div className="total">
															<div className="bullet"></div>
															<div>
																<span>
																	{capacityData?.areas[area.id].seats -
																		capacityData?.areas[area.id].ocuppedSeats}
																</span>
															</div>
														</div>
														<div className="sala">
															<div className="bullet green"></div>
															<div>
																<span>
																	{capacityData?.areas[area.id].ocuppedSeats}
																</span>
															</div>
														</div>
													</div>
												)}
										</div>
										<div
											className={`cont-cont ${
												area.type && area.type.type === 'seat'
													? 'type-seat'
													: 'type-screen'
											}`}
										>
											{area.type && area.type.type === 'seat' ? (
												area.rows &&
												area.rows.map((row, indexRow) => (
													<div
														className="area-row"
														key={indexRow}
													>
													<div className="area-row-fila">
															<span>{row.rowNumber}</span>
														</div>
														{row.seats &&
															row.seats.map((seat, seatIndex) => {
																return (
																	<div
																		key={seatIndex}
																		data-area={indexArea}
																		data-row={indexRow}
																		data-seat={seatIndex}
																		data-seatid={seat.id}
																		data-asistente={
																			seat.asistente ? true : false
																		}
																		data-seattype={seat.type}
																		className="area-row-seat"
																	>
																		<SeatEditEvent
																			eventoPasado={eventDataSimple.eventoPasado}
																			seatDetails={seat}
																			areaIx={indexArea}
																			rowIx={indexRow}
																			seatIx={seatIndex}
																			isSittingPeople={
																				selectedAsistente ? true : false
																			}
																			isEditingSections={
																				selectedSection.value ? true : false
																			}
																			updateSeatTypeFn={updateSeatSectionSingle}
																			updateSittingFn={updateSittingMap}
																		/>
																	</div>
																);
															})}
													</div>
												))
											) : (
												<div className="area-screen">{area.title}</div>
											)}
										</div>
									</div>
								</Rnd>
							))}
						</div>
					</div>
					<Selecto
						dragContainer=".map-scroll"
						dragCondition={(e) =>
							cursorIsHand ? false : selectedSection.value ? true : false
						}
						selectableTargets={['.area-row-seat']}
						hitRate={40}
						selectFromInside={true}
						selectByClick={false}
						continueSelect={false}
						onSelectEnd={(e) => updateSeatSectionMulti(e.afterAdded)}
					/>
				</>
			) : (
				<div className="noMap">
					<h3>No hay zonas disponibles</h3>
				</div>
			)}
			</div>
		</>
	);
};

export default EventDBPlanos;
