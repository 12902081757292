import React, { useRef, useState } from 'react';
import _ from 'lodash';

import { Col, Row, Button } from 'reactstrap';

//forms
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import InputSelect from 'components/common/forms/InputSelect';

//notifications
import { unsavedChangesModal } from 'components/common/modals/SwalModals';

const PlanoBulkNoSitting = ({
	isOpen,
	asistentesSelected = [],
	salas = [],
	closeModalFn,
	saveModalFn,
	isLoadingSelect
}) => {
	const formikRef = useRef();

	const validationSchema = Yup.object().shape({
		sala: Yup.object().shape({
			value: Yup.number().required(),
		}),
		area: Yup.object().shape({
			value: Yup.number().required(),
		}),
	});

	const initialBulkData = {
		sala: null,
		area: null,
	};

	const closeModal = () => {
		if (formikRef.current && formikRef.current.dirty) {
			unsavedChangesModal(() => closeModalFn());
		} else {
			closeModalFn();
		}
	};

	const bulkUpdateContacts = (values) => {

		const updateInvitados = {
			areaId:values.area.value || null,
			mapaId: values.sala.mapaId || null,
			invitados: asistentesSelected.map((inv) => inv.invitadoId),
		};

		saveModalFn(updateInvitados);
	};

	//room filter
	const [zoneListSelect, setZoneListSelect] = useState([]);
	const sumSelectedSeats = _.sumBy(asistentesSelected, 'totalAsistentesSinSitting');

	const availableZones = (roomList) => {
		
		if(roomList && roomList.areas) {
			const availableZones = roomList.areas.filter((ar) => ar.maxArea >= sumSelectedSeats);
			setZoneListSelect(availableZones);
			availableZones.length > 0 && formikRef && formikRef.current && formikRef.current.setFieldValue('area',availableZones[0])
		} else {
			setZoneListSelect([]);
		}
		
	};

	return (
		<Formik
			innerRef={formikRef}
			enableReinitialize
			initialValues={initialBulkData}
			validateOnChange={false}
			validateOnBlur={false}
			validationSchema={validationSchema}
			onSubmit={bulkUpdateContacts}
		>
			{({ values, setFieldValue, dirty }) => {
				return (
					<div
						className={`overlay-bottom-dashboard full-w ${
							isOpen ? 'show' : ''
						}`}
					>
						<Form autoComplete="off">
							<div className="modal-header">
								<div className="title">
									Asignación múltiple
									<span>{sumSelectedSeats} butacas</span>
								</div>

								{dirty ? (
									<div className="action-buttons">
										<Button
											type="button"
											onClick={closeModal}
											className="btn-sec-light"
										>
											Cancelar
										</Button>
										<Button type="submit" className="btn-sec-dark">
											Guardar
										</Button>
									</div>
								) : (
									<div className="close" onClick={closeModal}>
										<i className="fticon-close"></i>
									</div>
								)}
							</div>
							<div className="modal-body">
								<Row className="mt-2 mx-0">
									<Col md="2">
										<Field
											label="Sala"
											placeholder="Sala"
                                 options={salas}
											name="sala"
											component={InputSelect}
                                 menuPlacement="top"
											isClearable
											disabled={isLoadingSelect}
											isLoading={isLoadingSelect}
											onChange={(e) => {
												setFieldValue('sala', e);
												setFieldValue('area', null);
												availableZones(e);
											}}
                                 help={'No hay salas disponibles.'}
										/>
									</Col>
									<Col md="2">
										<Field
											label="Zona"
											placeholder="Zona"
                                 options={zoneListSelect}
											name="area"
											component={InputSelect}
                                 menuPlacement="top"
											disabled={values.sala ? false : true}
                                 help={'No hay zonas disponibles.'}
										/>
									</Col>
								</Row>
							</div>
						</Form>
					</div>
				);
			}}
		</Formik>
	);
};

export default PlanoBulkNoSitting;
