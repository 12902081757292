import React, { useState, useEffect, useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';
import _ from 'lodash';

//componentes
import { Button } from 'reactstrap';
import LoadingLayer from 'components/loaders/LoadingLayer';
import MapToolBar from './MapToolBar';
import MapEditAreaForm from './MapEditAreaForm';
import SeatEditPlano from 'components/common/maps/SeatEditPlano';
import SeatSimple from 'components/common/maps/SeatSimple';
import MapNumericForm from './MapNumericForm';

//services
import * as mapasApi from 'api/mapasApi';

//formik
import { Formik } from 'formik';
import * as Yup from 'yup';

//drag and resize
import useDraggableScroll from 'use-draggable-scroll';
import { Rnd } from 'react-rnd';
import Selecto from 'react-selecto';

//notifications
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as notify from 'utils/notify';
import { deleteItemModal } from 'components/common/modals/SwalModals';

const MapEditor = () => {
	let match = useRouteMatch('/mapa/:idmap');
	const mapID = match ? match.params.idmap : null;
	const MySwal = withReactContent(Swal);

	const formikRef = useRef();
	const formikRefRows = useRef();

	const [mapData, setMapData] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [pageLoading, setPageLoading] = useState(false);
	const [currentZoom, setCurrentZoom] = useState(100);
	const [sectionsMenuOpen, setSectionsMenuOpen] = useState(false);
	const [seatsMenuOpen, setSeatsMenuOpen] = useState(false);

	const [cursorIsHand, setCursorIsHand] = useState(false);
	const [capacityData, setCapacityData] = useState({
		empty: 0,
		handicap: 0,
		nula: 0,
		seats: 0,
		totalSeats: 0,
		areas: [],
	});

	const [areasType, setAreasType] = useState([]);
	const [sectionsType, setSectionsType] = useState([]);
	const [selectedSection, setSelectedSection] = useState({ type: null });

	const [editingArea, setEditingArea] = useState(false);
	const [editingAreaData, setEditingAreaData] = useState({});
	const [editingAreaRows, setEditingAreaRows] = useState({});
	const [editingAreaID, setEditingAreaID] = useState(null);
	const [editingAreaIndex, setEditingAreaIndex] = useState(null);
	const [editingTempSites, setEditingTempSites] = useState([]);
	const [editingTempWidth, setEditingTempWidth] = useState(0);

	const [masiveRowSelected, setMasiveRowSelected] = useState({type:null,area:null, fila:null, updateNum:null});
	const [editingMasiveRow, setEditingMasiveRow] = useState(null);

	useEffect(() => {
		getMapDetails(mapID);
		getAreasTypes();
		getSectionsTypes();
	}, [mapID]);

	//------------------------ GENÉRICAS

	const getMapDetails = async (idMapa) => {
		setPageLoading(true);

		const data = await mapasApi.getMap(idMapa);
		if (data && data.succeeded) {
			setMapData(data.data);
			setCapacityData(data.data.capacity);
		} else {
			notify.error(
				`Ups! Ha ocurrido un error cargando el mapa. ${data.message}`
			);
		}

		setPageLoading(false);
	};

	const getAreasTypes = async () => {
		const data = await mapasApi.getAreasType();
		setAreasType(data.data);
	};

	const getSectionsTypes = async () => {
		const data = await mapasApi.getSectionsType();
		setSectionsType(data.data);
	};

	//------------------------ AREAS

	const validationSchema = Yup.object().shape({
		title: Yup.string().required(),
	});

	const validationSchemaRows = Yup.object().shape({
		valueNumber: Yup.number().nullable().when('isEmpty', {
			is: false,
			then: Yup.number().required(),
		}),
	});

	const addAreaToMap = async (area) => {
		setIsLoading(true);
		const data = await mapasApi.createArea({
			mapaValue: parseInt(mapID),
			mapaTipoAreaId: parseInt(area.value),
		});
		if (data && data.succeeded) {
			const newArea = mapData.areas;
			newArea.push(data.data.area);
			setMapData({ ...mapData, areas: newArea });
			setCapacityData(data.data.capacityMap);
		} else {
			notify.error(data.message);
		}
		setIsLoading(false);
	};

	const removeArea = (areaRmID) => {
		deleteItemModal('el área', async () => {
			setIsLoading(true);
			const data = await mapasApi.deleteArea(areaRmID);
			if (data && data.succeeded) {
				const newMapData = _.remove(
					mapData.areas,
					(area) => area.id !== areaRmID
				);
				setMapData({ ...mapData, areas: newMapData });
				setCapacityData(data.data);
			} else {
				notify.error(
					`Ha ocurrido un error eliminando el área. ${data.message}`
				);
			}
			setIsLoading(false);
		});
	};

	//actualización info de area
	const updateAreaData = async (values, actions) => {
		setIsLoading(true);
		const data = await mapasApi.updateArea({
			...values,
			pos: {
				x: parseInt(values.pos.x),
				y: parseInt(values.pos.y),
				z: parseInt(values.pos.z),
			},
			rows: editingTempSites,
		});
		if (data && data.succeeded) {
			const updatedID = parseInt(data.data.area.id);
			const currentAreas = mapData.areas;
			var areaEditedIndex = _.findIndex(currentAreas, { id: updatedID });
			currentAreas.splice(areaEditedIndex, 1, data.data.area);
			setMapData({ ...mapData, areas: currentAreas });
			setCapacityData(data.data.capacityMap);

			//defaults
			toggleEditingArea();
			setEditingArea(false);
			setEditingAreaData(null);
			setEditingAreaRows({});
			setEditingAreaID(null);
			setEditingAreaIndex(null);
			setEditingTempSites([]);
		} else {
			notify.error(
				`Ha ocurrido un error actualizando el área. ${data.message}`
			);
		}
		setIsLoading(false);
	};

	const deleteArea = (e) => {
		removeArea(e.id);
	};

	const editArea = (area, areaIdx) => {
		setSeatsMenuOpen(false);
		setSectionsMenuOpen(false);
		setEditingAreaData({
			...area,
			beforeSize: area.size,
			beforePos: area.pos,
			beforeSeats: area.seats,
		});
		setEditingAreaRows(_.cloneDeep(area.rows));
		setEditingAreaID(area.id);
		setEditingAreaIndex(areaIdx);
		setEditingTempSites(area.rows);
		setSelectedSection({ type: null });
		setEditingTempWidth(area.size.w);
		setEditingArea(true);
	};

	//actualización posiciones de area
	const stopDragPos = async (e, areaMoving, xPos, yPos, dPos) => {
		e.preventDefault();

		const data = await mapasApi.moveArea({
			id: parseInt(areaMoving),
			pos: { x: parseInt(xPos), y: parseInt(yPos), z: parseInt(dPos) },
		});

		if (data && data.succeeded) {
			const currentAreas = mapData.areas;
			var areaEdited = _.find(currentAreas, { id: parseInt(areaMoving) });
			var areaEditedIndex = _.findIndex(currentAreas, {
				id: parseInt(areaMoving),
			});

			currentAreas.splice(areaEditedIndex, 1, {
				...areaEdited,
				pos: { x: parseInt(xPos), y: parseInt(yPos), z: parseInt(dPos) },
			});

			setMapData({ ...mapData, areas: currentAreas });
		} else {
			notify.error(`Ha ocurrido un error eliminando el área. ${data.message}`);
		}
	};

	//actualización tamaño de area
	const updateAreaSize = (
		areaIdx,
		areaID,
		widthW,
		heightH,
		totalSeats,
		position
	) => {
		const currentAreas = mapData.areas;
		const areaEdited = _.find(currentAreas, { id: parseInt(areaID) });

		currentAreas.splice(areaIdx, 1, {
			...areaEdited,
			size: { w: widthW, h: heightH },
			pos: { ...areaEdited.pos, x: position.x, y: position.y },
			seats: totalSeats,
			rows: editingTempSites,
		});

		setMapData({ ...mapData, areas: currentAreas });
	};

	const closeModalMenu = () => {
		if (formikRef.current && formikRef.current.dirty) {
			MySwal.fire({
				title: 'Cambios sin guardar',
				text: 'Si cierras la ventana se perderán los cambios.',
				cancelButtonText: 'Cancelar',
				showCancelButton: true,
				confirmButtonText: 'Cerrar',
				focusConfirm: false,
				focusCancel: false,
				reverseButtons: true,
				buttonsStyling: false,
				customClass: {
					confirmButton: 'btn-prim-dark btn',
					cancelButton: 'btn-prim-light btn',
				},
			}).then((result) => {
				if (result && result.isConfirmed) {
					//restaura a valores originales
					const currentAreas = mapData.areas;
					var areaEdited = _.find(currentAreas, {
						id: parseInt(editingAreaID),
					});
					var areaEditedIndex = _.findIndex(currentAreas, {
						id: parseInt(editingAreaID),
					});

					currentAreas.splice(areaEditedIndex, 1, {
						...areaEdited,
						size: editingAreaData.beforeSize,
						pos: editingAreaData.beforePos,
						seats: editingAreaData.beforeSeats,
						rows: editingAreaRows,
					});

					setMapData({ ...mapData, areas: currentAreas });
					refElems.current[editingAreaIndex].updatePosition(
						editingAreaData.beforePos
					);
					refElems.current[editingAreaIndex].updateSize({
						width: editingAreaData.beforeSize.w,
						height: editingAreaData.beforeSize.h,
					});
					updateRotation(editingAreaData.beforePos.z, editingAreaIndex);

					//defaults
					toggleEditingArea();
					setEditingAreaData(null);
					setEditingAreaRows({});
					setEditingArea(null);
					setEditingAreaID(null);
					setEditingAreaIndex(null);
					setEditingTempSites([]);
					setEditingTempWidth(0);
				}
			});
		} else {
			//defaults
			toggleEditingArea();
			setEditingAreaData(null);
			setEditingAreaRows({});
			setEditingArea(null);
			setEditingAreaID(null);
			setEditingAreaIndex(null);
			setEditingTempSites([]);
			setEditingTempWidth(0);
		}
	};

	const toggleEditingArea = () => {
		setEditingArea(!editingArea);
	};

	//------------------------ SECCIONES

	const selectSection = async (section) => {
		setSelectedSection(section);
	};

	//------------------------ MAPS

	const refElems = useRef([]);

	const toggleCursor = () => {
		setCursorIsHand(!cursorIsHand);
	};

	//actualización texto de asiento
	const updateSeatInMap = async (newVals) => {
		if (newVals.id) {
			const data = await mapasApi.updateTextSeats([
				{ id: newVals.id, text: newVals.text },
			]);
			if (data && data.succeeded) {
				const newMap = (mapData.areas[newVals.areaIndex].rows[
					newVals.rowIndex
				].seats[newVals.seatIndex] = newVals);
				setMapData({ ...mapData, newMap });
			} else {
				notify.error(
					`Ha ocurrido un error actualizando el asiento. ${data.message}`
				);
			}
		}
	};

	//actualización tipo de asiento (simple)
	const updateSeatSectionSingle = async (
		seat,
		areaIndex,
		rowIndex,
		seatIndex
	) => {
		if (seat && seat.id) {
			const updatedValues = {
				id: seat.id,
				type: selectedSection.value ? 'seat' : selectedSection.type,
				sectionId: selectedSection.value ? selectedSection.value : null,
				section: selectedSection.value ? selectedSection : null,
			};

			const data = await mapasApi.updateSectionSeats({
				mapaId: mapData.value,
				seats: [updatedValues],
			});
			if (data && data.succeeded) {
				const newMap = (mapData.areas[areaIndex].rows[rowIndex].seats[
					seatIndex
				] = {
					...mapData.areas[areaIndex].rows[rowIndex].seats[seatIndex],
					type: updatedValues.type,
					sectionId: updatedValues.sectionId,
					section: updatedValues.section,
				});

				setMapData({ ...mapData, newMap });
				setCapacityData(data.data);
			} else {
				notify.error(
					`Ha ocurrido un error actualizando el asiento. ${data.message}`
				);
			}
		}
	};

	//actualización tipo de asiento (multi - drag)
	const updateSeatSectionMulti = async (seats) => {
		if (seats && seats.length > 0) {
			setIsLoading(true);
			const updatedSeats = seats.map((seat) => ({
				id: parseInt(seat.dataset.seatid),
				type: selectedSection.value ? 'seat' : selectedSection.type,
				sectionId: selectedSection.value ? selectedSection.value : null,
				section: selectedSection.value ? selectedSection : null,
			}));

			const data = await mapasApi.updateSectionSeats({
				mapaId: mapData.value,
				seats: updatedSeats,
			});
			if (data && data.succeeded) {
				const newSeat = seats.map((seat) => {
					return (mapData.areas[seat.dataset.area].rows[seat.dataset.row].seats[
						seat.dataset.seat
					] = {
						...mapData.areas[seat.dataset.area].rows[seat.dataset.row].seats[
							seat.dataset.seat
						],
						type: selectedSection.value ? 'seat' : selectedSection.type,
						sectionId: selectedSection.value ? selectedSection.value : null,
						section: selectedSection.value ? selectedSection : null,
					});
				});

				setMapData({ ...mapData, newSeat });
				setCapacityData(data.data);
			} else {
				notify.error(
					`Ha ocurrido un error actualizando el asiento. ${data.message}`
				);
			}
			setIsLoading(false);
		}
	};

	const openSeatsMenu = () => {
		if (editingAreaID) {
			closeModalMenu();
		} else {
			setSeatsMenuOpen(!seatsMenuOpen);
		}
	};

	const openSectionsMenu = () => {
		if (editingAreaID) {
			closeModalMenu();
		} else {
			setSectionsMenuOpen(!sectionsMenuOpen);
		}
	};

	const mapScrollRef = useRef(null);
	const { onMouseDown } = useDraggableScroll(mapScrollRef);

	// ROTACIONES
	const updateRotation = (deg, areaIndex) => {
		document.getElementsByClassName(
			`idxcont-${areaIndex}`
		)[0].style.transform = `rotate(${deg}deg)`;
		document.getElementsByClassName(
			`idxhand-${areaIndex}`
		)[0].style.transform = `rotate(${deg}deg)`;
	};

	// BULK UPDATES
	const handleBulkUpdateMasive = (type, toUpdate) => {

		Array.from(document.querySelectorAll('.seat-masive-selected')).forEach(
			(el) => el.classList.remove('seat-masive-selected')
		);

		const currentUpdate = {
			type: type,
			area: toUpdate.areaId,
			fila: toUpdate.filaId,
			updateNum: type === 'column' ? toUpdate.colNumber : toUpdate.rowNumber,
		};

		if (!_.isEqual(masiveRowSelected, currentUpdate)) {
			const allSeatsAffected =
				type === 'column'
					? mapData.areas[toUpdate.areaIndex].rows.map(
							(row) => row.seats[toUpdate.colNumber]
					  )
					: mapData.areas[toUpdate.areaIndex].rows[
							toUpdate.rowNumber
					  ].seats.map((seat) => seat);

			allSeatsAffected.length > 0 &&
				allSeatsAffected.map((seat) =>
					document
						.getElementById('btc-id-' + seat.id)
						.classList.add('seat-masive-selected')
				);

			const currentSpace = _.countBy(allSeatsAffected, _.property('type'));

			setMasiveRowSelected(currentUpdate);
			setEditingMasiveRow({
				type: type,
				seats: allSeatsAffected,
				isEmpty: currentSpace.space === allSeatsAffected.length ? true : false,
				valueNumber: null,
				areaId: toUpdate.areaId,
				filaId: toUpdate.filaId,
			});
		} else { 
			setMasiveRowSelected({type:null, area:null, fila:null, updateNum:null});
			setEditingMasiveRow(null);
		}
	};

	const updateMasiveRow = async (rowDataValues, formActions) => {

		setIsLoading(true);
		
		const dataPost = {
			mapId: parseInt(mapID),
			areaId: rowDataValues.areaId,
			asientos: rowDataValues.seats,
			fila: rowDataValues.type ==='row' ? { filaId: rowDataValues.filaId, filaVacia: rowDataValues.isEmpty, rowNumber: rowDataValues.valueNumber } : null,
			columna: rowDataValues.type ==='column' ? { asientoText: rowDataValues.valueNumber, columnaVacia: rowDataValues.isEmpty } : null,
		};

		const data = await mapasApi.updateBulkFilaColumna(dataPost);
		if (data && data.succeeded) {

			const updatedID = parseInt(data.data.area.id);
			const currentAreas = mapData.areas;
			var areaEditedIndex = _.findIndex(currentAreas, { id: updatedID });
			currentAreas.splice(areaEditedIndex, 1, data.data.area);
			setMapData({ ...mapData, areas: currentAreas });
			setCapacityData(data.data.capacityMap);

			//restore
			cleanMasiveRowsSelecteds();

		} else {
			notify.error(
				`Ha ocurrido un error actualizando la ${rowDataValues.type === 'row' ? 'fila':'columna'}. ${data.message}`
			);
		}
		
		setIsLoading(false);
	};

	const cleanMasiveRowsSelecteds = () => {
		Array.from(document.querySelectorAll('.seat-masive-selected')).forEach(
			(el) => el.classList.remove('seat-masive-selected')
		);
		setMasiveRowSelected({type:null, area:null, fila:null, updateNum:null});
		setEditingMasiveRow(null);
	};

	//comprobar asientos duplicados
	const checkMapDuplicates = async () => {
		setIsLoading(true);
		cleanSeatsDuplicated();

		const data = await mapasApi.getErroresMapa(parseInt(mapID));
		if (data && data.succeeded) {
			if (data.data && data.data.length > 0) {
				const duplicateds = data.data;
				notify.pending(
					`Se han encontrado ${duplicateds.length} asiento${duplicateds.length === 1 ? '':'s'} duplicado${duplicateds.length === 1 ? '':'s'}.`
				);
				duplicateds.map((seat) =>
					document
						.getElementById('btc-id-' + seat)
						.classList.add('seat-duplicated-selected')
				);
			} else {
				notify.success('No hay ningún número de asiento duplicado.');
			}
		} else {
			notify.error(data.message);
		}
		setIsLoading(false);
	};

	const cleanSeatsDuplicated = () => {
		Array.from(document.querySelectorAll('.seat-duplicated-selected')).forEach(
			(el) => el.classList.remove('seat-duplicated-selected')
		);
	};





	const closeModalRowMenu = () => {
		if (formikRef.current && formikRef.current.dirty) {
			MySwal.fire({
				title: 'Cambios sin guardar',
				text: 'Si cierras la ventana se perderán los cambios.',
				cancelButtonText: 'Cancelar',
				showCancelButton: true,
				confirmButtonText: 'Cerrar',
				focusConfirm: false,
				focusCancel: false,
				reverseButtons: true,
				buttonsStyling: false,
				customClass: {
					confirmButton: 'btn-prim-dark btn',
					cancelButton: 'btn-prim-light btn',
				},
			}).then((result) => {
				if (result && result.isConfirmed) {
					//restaura a valores originales
					cleanMasiveRowsSelecteds();
				}
			});
		} else {
			//defaults
			cleanMasiveRowsSelecteds();
		}
	};

	return (
		<>
			{pageLoading ? (
				<LoadingLayer visible={pageLoading} />
			) : (
				<>
					{isLoading && <LoadingLayer visible={isLoading} />}
					<MapToolBar
						newAreaFn={addAreaToMap}
						checkMapDuplicatesFn={checkMapDuplicates}
						selectedSection={selectedSection}
						selectedSectionFn={selectSection}
						areasList={areasType}
						sectionsList={sectionsType}
						mapData={mapData}
						capacityMap={capacityData}
						currentZoom={currentZoom}
						updateCurrentZoom={setCurrentZoom}
						cursorHand={cursorIsHand}
						toggleCursor={toggleCursor}
						seatsMenuOpen={seatsMenuOpen}
						seatsMenuFn={openSeatsMenu}
						sectionsMenuOpen={sectionsMenuOpen}
						sectionsMenuFn={openSectionsMenu}
					/>

					{mapData.areas && mapData.areas.length > 0 ? (
						<>
							<div
								className={`map-scroll ${
									cursorIsHand ? 'is-dragging' : 'is-static'
								} ${selectedSection.type ? 'has-section' : 'no-section'}`}
								ref={mapScrollRef}
								onMouseDown={(e) => cursorIsHand && onMouseDown(e)}
							>
								<div className="map-canvas" style={{ transform: `scale(${currentZoom/100})` }}>
									{mapData.areas.map((area, indexArea) => (
										<Rnd
											key={area.id}
											ref={(ref) => (refElems.current[indexArea] = ref)}
											className={`area-elem ${
												editingAreaID ? 'edit-open' : ''
											} ${editingAreaID === area.id ? 'edit-current' : ''}`}
											default={{
												x: area.pos ? area.pos.x : 0,
												y: area.pos ? area.pos.y : 0,
											}}
											size={{
												width: area.size ? area.size.w : 'auto',
												height: area.size ? area.size.h : 'auto',
											}}
											onDragStop={(e, d) => {
												if (formikRef.current) {
													formikRef.current.setFieldValue(
														'pos.x',
														parseInt(d.x)
													);
													formikRef.current.setFieldValue(
														'pos.y',
														parseInt(d.y)
													);
												} else {
													stopDragPos(
														e,
														area.id,
														d.x,
														d.y,
														formikRef.current
															? formikRef.current.values.pos.z
															: area.pos.z
													);
												}
											}}
											dragHandleClassName="fticon-move"
											resizeGrid={[20, 23]}
											minWidth={
												area.type && area.type.type === 'seat' ? 135 : 300
											}
											minHeight={115}
											scale={parseFloat((currentZoom / 100).toFixed(2))}
											enableResizing={
												editingAreaID === area.id
													? {
															top: true,
															right: true,
															bottom: true,
															left: true,
															topRight: false,
															bottomRight: false,
															bottomLeft: false,
															topLeft: false,
													  }
													: false
											}
											disableDragging={
												cursorIsHand
													? true
													: selectedSection.type
													? true
													: false
											}
											resizeHandleClasses={{
												top: 'rplus top',
												right: 'rplus right',
												bottom: 'rplus bottom',
												left: 'rplus left',
											}}
											resizeHandleComponent={{
												top: <i className="fticon-plus"></i>,
												right: <i className="fticon-plus"></i>,
												bottom: <i className="fticon-plus"></i>,
												left: <i className="fticon-plus"></i>,
											}}
											onResizeStop={(e, direction, ref, delta, position) => {
												if (formikRef.current) {
													let tempTotalSeats = 0;
													if (area.type && area.type.type === 'seat') {
														let currentWidth = parseInt(ref.style.width) - 20;
														let currentHeight = parseInt(ref.style.height);
														let seatsLine = parseInt(
															(currentWidth / 20).toFixed(0) - 1
														);
														let seatsRow = parseInt(
															(currentHeight / 23).toFixed(0) - 3
														);
														tempTotalSeats = parseInt(seatsRow * seatsLine);
													}
													updateAreaSize(
														indexArea,
														area.id,
														ref.style.width,
														ref.style.height,
														tempTotalSeats,
														position
													);
													formikRef.current.setFieldValue(
														'size.w',
														parseInt(ref.style.width)
													);
													formikRef.current.setFieldValue(
														'size.h',
														parseInt(ref.style.height)
													);
													formikRef.current.setFieldValue('pos.x', position.x);
													formikRef.current.setFieldValue('pos.y', position.y);
													formikRef.current.setFieldValue(
														'seats',
														tempTotalSeats
													);
												}
											}}
											onResize={(e, direction, ref, delta, position) => {
												let updateLines = 0;
												let newSiteSetup = [];
												let currentWidth = parseInt(ref.style.width) - 20;
												let seatsLine = parseInt(
													(currentWidth / 20).toFixed(0) - 1
												);

												if (direction === 'right' || direction === 'left') {
													if (currentWidth !== editingTempWidth) {
														updateLines = parseInt(
															(delta.width / 20).toFixed(0)
														);
													} else {
														updateLines = parseInt(
															(Math.abs(delta.width) / 20).toFixed(0)
														);
													}

													newSiteSetup = area.rows;
													if (updateLines >= 0) {
														//añadir columnas
														const newRows = newSiteSetup.map((fila) => {
															if (seatsLine > fila.seats.length) {
																let cantNew = seatsLine - fila.seats.length;
																for (let i = 0; i < cantNew; i++) {
																	direction === 'right'
																		? fila.seats.push({
																				type: 'seat',
																				id: 0,
																		  })
																		: fila.seats.unshift({
																				type: 'seat',
																				id: 0,
																		  });
																}
															} else {
																let cantNew = fila.seats.length - seatsLine;
																if (cantNew > 0) {
																	fila.seats =
																		direction === 'right'
																			? _.dropRight(fila.seats, cantNew)
																			: _.drop(fila.seats, cantNew);
																}
															}
															return fila;
														});
														setEditingTempSites(newRows);
													} else {
														//quitar columnas
														const newRows = newSiteSetup.map((fila) => {
															let cantNew = fila.seats.length - seatsLine;
															if (cantNew > 0) {
																fila.seats =
																	direction === 'right'
																		? _.dropRight(fila.seats, cantNew)
																		: _.drop(fila.seats, cantNew);
															}
															return fila;
														});
														setEditingTempSites(newRows);
													}
													setEditingTempWidth(currentWidth);
												} else if (
													direction === 'top' ||
													direction === 'bottom'
												) {
													updateLines = parseInt(
														(delta.height / 23).toFixed(0)
													);
													if (updateLines >= 0) {
														//añadir lineas
														for (let i = 0; i < updateLines; i++) {
															newSiteSetup.push({
																seats: Array.from(
																	{ length: seatsLine },
																	() => ({
																		type: 'seat',
																		id: 0,
																	})
																),
															});
														}
														direction === 'top'
															? setEditingTempSites(
																	_.concat(newSiteSetup, area.rows)
															  )
															: setEditingTempSites(
																	_.concat(area.rows, newSiteSetup)
															  );
													} else {
														//quitar lineas
														newSiteSetup =
															direction === 'top'
																? _.drop(area.rows, Math.abs(updateLines))
																: _.dropRight(area.rows, Math.abs(updateLines));
														setEditingTempSites(newSiteSetup);
													}
												}
											}}
											bounds=".map-canvas"
											resizeHandleWrapperStyle={{
												zIndex: 0,
												transform: `rotate(${area.pos.z}deg)`,
												width: '100%',
												height: '100%',
												position: 'absolute',
												top: 0,
											}}
											resizeHandleWrapperClass={`idxhand-${indexArea}`}
										>
											<div
												className={`area-container idxcont-${indexArea} area-type-${area.type.type}`}
												style={{
													transform: `rotate(${area.pos.z}deg)`,
													zIndex: 1,
												}}
											>
												<div className="cont-header">
													<div className="cont-name">
														<i
															data-areaid={area.id}
															className="fticon-move"
														></i>
														{area.type && area.type.type === 'seat' && (
															<>
																<div className="seat-name">{area.title}</div>{' '}
																<div className="seat-count">
																	{_.has(capacityData.areas, area.id)
																		? capacityData.areas[area.id]
																		: 0}
																</div>
															</>
														)}
													</div>
													<div className="cont-actions">
														<Button
															onClick={(e) => {
																e.stopPropagation();
																deleteArea(area);
															}}
															className="btn-prim-light btn-icon"
														>
															<i className="fticon-trash"></i>
														</Button>
														<Button
															onClick={(e) => {
																e.stopPropagation();
																editArea(area, indexArea);
															}}
															className="btn-prim-light btn-icon"
														>
															<i className="fticon-edit"></i>
														</Button>
													</div>
												</div>
												<div
													className={`cont-cont ${
														area.type && area.type.type === 'seat'
															? 'type-seat'
															: 'type-screen'
													}`}
												>
													{area.type && area.type.type === 'seat' ? (
														editingAreaID === area.id ? (
															editingTempSites &&
															editingTempSites.map((row, indexRow) => (
																<div className="area-row" key={indexRow}>
																	<div
																		className="area-row-fila"
																		data-rownum={
																			editingTempSites.length - indexRow
																		}
																	>
																		<span>{row.rowNumber}</span>
																	</div>
																	{row.seats &&
																		row.seats.map((seat, seatIndex) => {
																			return (
																				<div
																					key={seatIndex}
																					className="area-row-seat"
																					data-seatnum={seatIndex + 1}
																				>
																					<SeatSimple seatDetails={seat} />
																				</div>
																			);
																		})}
																</div>
															))
														) : (
															area.rows && (
																<>
																	{area.rows.map((row, indexRow) => (
																		<div className="area-row" key={indexRow}>
																			<div
																				className="area-row-fila"
																				onClick={() =>
																					handleBulkUpdateMasive('row', {
																						rowNumber: indexRow,
																						areaIndex: indexArea,
																						areaId: area.id,
																						filaId: row.id
																					})
																				}
																			>
																				<span>{row.rowNumber}</span>
																			</div>
																			{row.seats &&
																				row.seats.map((seat, seatIndex) => {
																					return (
																						<div
																							key={seatIndex}
																							data-area={indexArea}
																							data-row={indexRow}
																							data-seat={seatIndex}
																							data-seatid={seat.id}
																							className="area-row-seat"
																						>
																							<SeatEditPlano
																								seatDetails={seat}
																								areaIx={indexArea}
																								rowIx={indexRow}
																								seatIx={seatIndex}
																								cursorIsHand={cursorIsHand}
																								isEditingSections={
																									selectedSection.type
																										? true
																										: false
																								}
																								updateSeatFn={updateSeatInMap}
																								updateSeatTypeFn={
																									updateSeatSectionSingle
																								}
																							/>
																						</div>
																					);
																				})}
																		</div>
																	))}
																	<div className="area-row">
																		<div className="area-row-guide">
																			<span></span>
																		</div>
																		{area.numSeatColum &&
																			_.times(area.numSeatColum, Number).map(
																				(dt, ixDt) => (
																					<div
																						key={ixDt}
																						className="area-row-guide"
																						onClick={() =>
																							handleBulkUpdateMasive('column', {
																								colNumber: ixDt,
																								areaIndex: indexArea,
																								areaId: area.id,
																								filaId: null
																							})
																						}
																					>
																						<span>{dt + 1}</span>
																					</div>
																				)
																			)}
																	</div>
																</>
															)
														)
													) : (
														<div className="area-screen">{area.title}</div>
													)}
												</div>
											</div>
										</Rnd>
									))}
								</div>
							</div>
							<Selecto
								dragContainer=".map-scroll"
								dragCondition={(e) =>
									cursorIsHand ? false : selectedSection.type ? true : false
								}
								selectableTargets={['.area-row-seat']}
								hitRate={40}
								selectFromInside={true}
								selectByClick={false}
								continueSelect={false}
								onSelectEnd={(e) => updateSeatSectionMulti(e.afterAdded)}
							/>
						</>
					) : (
						<div className="noMap">
							<h3>Aún no hay nada creado</h3>
							<p>
								Haz click en el <strong>Botón editar</strong> para crear una
								zona de butacas.
							</p>
						</div>
					)}

					{editingArea && (
						<Formik
							innerRef={formikRef}
							enableReinitialize
							initialValues={
								editingAreaData && editingAreaData.id ? editingAreaData : {}
							}
							validationSchema={validationSchema}
							validateOnChange={false}
							validateOnBlur={false}
							onSubmit={(values, actions) => updateAreaData(values, actions)}
						>
							{({ values, setFieldValue }) => {
								return (
									<>
										<MapEditAreaForm
											isOpen={editingArea}
											areaData={editingAreaData}
											areaIndex={editingAreaIndex}
											toggleModal={closeModalMenu}
											formikValues={values}
											formikSetField={setFieldValue}
											updateRotation={updateRotation}
										/>
									</>
								);
							}}
						</Formik>
					)}
					{editingMasiveRow && (
						<Formik
							innerRef={formikRefRows}
							enableReinitialize
							initialValues={editingMasiveRow}
							validationSchema={validationSchemaRows}
							validateOnChange={false}
							validateOnBlur={false}
							onSubmit={(values, actions) => updateMasiveRow(values, actions)}
						>
							{({ values, setFieldValue }) => {
								return (
									<>
										<MapNumericForm
											isOpen={editingMasiveRow ? true : false}
											areaData={editingMasiveRow}
											toggleModal={closeModalRowMenu}
											formikValues={values}
											formikSetField={setFieldValue}
										/>
									</>
								);
							}}
						</Formik>
					)}
				</>
			)}
		</>
	);
};

export default MapEditor;
