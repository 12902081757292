import React, { useRef, useState, useEffect } from 'react';
import _ from 'lodash';

import { Col, Row, ModalBody, Button, ModalFooter } from 'reactstrap';

//componentes
import FullLateralModal from 'components/common/modals/FullLateralModal';
import LateralModal from 'components/common/modals/LateralModal';
import WhiteBox from 'components/common/elems/WhiteBox';
import InvitationMailPreview from 'components/common/mail/InvitationMailPreview';
import EntradaPreviewModal from 'pages/events/dashboard/modals/EntradaPreviewModal';
import ContactsFilter from '../../contacts/ContactsFilter';
import TableLoader from 'components/loaders/TableLoader';
import LoadingLayer from 'components/loaders/LoadingLayer';
import TableVipFilter from 'components/common/elems/tables/TableVipFilter';
import TableRowFilter from 'components/common/elems/tables/TableRowFilter';
import TableRowOrder from 'components/common/elems/tables/TableRowOrder';
import TableDropEdit from 'components/common/elems/tables/TableDropEdit';
import EntradaSentDetails from './modals/EntradaSentDetails';
import EntradasUnifyModal from './modals/EntradasUnifyModal';
import InfiniteScroll from 'react-infinite-scroller';
import Portal from '@mui/base/Portal';

//services
import * as eventsApi from 'api/eventsApi';

//forms
import { Formik, Form, Field } from 'formik';
import { IMAGE_TYPES_ALLOWED } from 'utils/helpers';
import InputImage from 'components/common/forms/InputImage';
import CheckboxNF from 'components/common/forms/CheckboxNF';
import InputForm from 'components/common/forms/InputForm';
import InputSelect from 'components/common/forms/InputSelect';

//notifications
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as notify from 'utils/notify';

//helpers
import { defEntradasFilter, noSpaceString } from 'utils/helpers';

const EventDBnuevaEntrada = ({
	modalNuevaOpen,
	toggleModalBar,
	entradaData,
	setEntradaData,
	handleSubmitForm,
	handleSendEntrada,
	eventDataSimple,
	companyLogos,
}) => {
	useEffect(() => {
		setchangeName(false);
		setchangeAsunto(false);
		setchangeDe(false);
	}, []);

	const formikRef = useRef();
	const MySwal = withReactContent(Swal);

	const [entradaStep, setEntradaStep] = useState(
		entradaData && entradaData.fechaEnvio ? 3 : 0
	);

	const [changeName, setchangeName] = useState(false);
	const [changeAsunto, setchangeAsunto] = useState(false);
	const [changeCompany, setChangeCompany] = useState(false);
	const [changeDe, setchangeDe] = useState(false);

	const [tagList, setTagList] = useState([]);
	const [tagListModal, setTagListModal] = useState([]);
	const [companies, setCompanies] = useState([]);
	const [companiesModal, setCompaniesModal] = useState([]);
	const [origins, setOrigins] = useState([]);
	const [originsModal, setOriginsModal] = useState([]);

	//destinatarios
	const destinatariosPortalRef = useRef(null);
	const [currentFilter, setCurrentFilter] = useState({});
	const [destinatariosList, setDestinatariosList] = useState([]);
	const [destinatariosTotal, setDestinatariosTotal] = useState(0);
	const [destinatariosTotalPage, setDestinatariosTotalPage] = useState(0);

	const [showDestinatariosModal, setShowDestinatariosModal] = useState(false);

	const [destinatariosModalList, setDestinatariosModalList] = useState([]);
	const [destinatariosModalTotalFilter, setDestinatariosModalTotalFilter] =
		useState(0);

	const [tempDestinatariosSelected, setTempDestinatariosSelected] = useState(
		[]
	);

	const [destinatariosGuardadosList, setDestinatarioGuardadosList] = useState(
		[]
	);
	const [noFilterDestinatList, setNoFilterDestinatList] = useState([]);

	const [allDestSelected, setAllDestSelected] = useState(false);
	const [isLoadingModal, setIsLoadingModal] = useState(false);
	const [isLoadingDestinat, setIsLoadingDestinat] = useState(false);
	const [pageLoading, setPageLoading] = useState(false);
	const [actionsDisabled, setActionsDisabled] = useState(false);

	const [showModalPDF, setshowModalPDF] = useState(false);
	const [boolEntryHour, setBoolEntryHour] = useState(false);
	const [boolRoomSeat, setBoolRoomSeat] = useState(false);

	useEffect(() => {
		if (entradaStep === 0) {
			//home invitacion
		} else if (entradaStep === 1) {
			//preview
		} else if (entradaStep === 2) {
			//destinatarios

			//reset de listados
			setDestinatariosTotal(0);
			setDestinatariosList([]);
			setDestinatariosModalList([]);

			setDestinatarioGuardadosList([]);
			setNoFilterDestinatList([]);

			//api calls
			//getTags();
			//getCompanies();
			//getOrigins();
			resetFilters();
			getDestinatarios(currentFilter, true, true);
		} else if (entradaStep === 3) {
			//dashboard
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entradaStep]);

	const newEntrada = {
		id: 0,
		nombre: '',
		asunto: '',
		sendFromNombre: '',
		sendFromEmail: '',
		eventoId: eventDataSimple.id,
		companyLogo: '',
	};

	const closeModalMenu = () => {
		if (formikRef.current && formikRef.current.dirty) {
			MySwal.fire({
				title: 'Cambios sin guardar',
				text: 'Si cierras la ventana se perderán los cambios.',
				cancelButtonText: 'Cancelar',
				showCancelButton: true,
				confirmButtonText: 'Cerrar',
				focusConfirm: false,
				focusCancel: false,
				reverseButtons: true,
				buttonsStyling: false,
				customClass: {
					confirmButton: 'btn-prim-dark btn',
					cancelButton: 'btn-prim-light btn',
				},
			}).then((result) => {
				if (result && result.isConfirmed) {
					formikRef.current.resetForm();
					toggleModalBar();
				}
			});
		} else {
			toggleModalBar();
		}
	};

	const toggleModalEntradaPreview = (entryHour = false, showRow = false) => {
		setBoolEntryHour(entryHour);
		setBoolRoomSeat(showRow);
		setshowModalPDF(!showModalPDF);
	};

	const reloadEntrada = async (startStep = false) => {
		setPageLoading(true);
		startStep && setEntradaStep(0);
		const data = await eventsApi.getEntrada(entradaData.id);
		setEntradaData(data.data);
		setPageLoading(false);
	};

	//preview
	const templateIsReadyToSend = (values) => {
		if (
			values &&
			values.firmaCargo &&
			values.firmaTextoLegal &&
			values.textoPrincipal
		) {
			return true;
		} else {
			return false;
		}
	};

	const entradaIsReadyToSend = (values) => {
		if (
			values &&
			values.asunto &&
			values.companyLogo &&
			values.sendFromNombre &&
			values.sendFromEmail &&
			values.firmaCargo &&
			values.firmaTextoLegal &&
			values.textoPrincipal &&
			values.totalDestinatariosUpdate > 0
		) {
			return true;
		} else {
			return false;
		}
	};
	//destinatarios
	const [disableHandleAdd, setDisableHandleAdd] = useState(false);

	//filtros
	const [filterVip, setFilterVip] = useState(null);
	const [filterEntrada, setFilterEntrada] = useState(null);
	const [filterOrder, setFilterOrder] = useState(null);

	const [filterVipModal, setFilterVipModal] = useState(null);
	const [filterEntradaModal, setFilterEntradaModal] = useState(null);
	const [filterOrderModal, setFilterOrderModal] = useState(null);
	const [unifyContacts, setUnifyContacts] = useState([]);

	useEffect(() => {
		showDestinatariosModal && getDestinatariosModal();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showDestinatariosModal]);

	const getDestinatarios = async (
		filters = currentFilter,
		table = false,
		firstLoad = false
	) => {
		table ? setPageLoading(true) : setIsLoadingDestinat(true);
		setActionsDisabled(true);

		const data = await eventsApi.getReceiversEntradas({
			...filters,
			entradaId: parseInt(entradaData.id),
			pagina: 1,
		});
		if (data && data.succeeded) {
			setDestinatariosList(data.data.receiverList);
			setDestinatariosTotal(data.data.totalReceiverEntrada);
			setDestinatariosTotalPage(data.data.totalReceiver);

			setNoFilterDestinatList(data.data.receiverListSinFiltrar);
			firstLoad &&
				setDestinatarioGuardadosList(data.data.receiverListSinFiltrar);

			setTagList(data.data.selectEtiquetas);
			setCompanies(data.data.selectEmpresas);
			setOrigins(data.data.selectProcedencias);
			setCurrentFilter({ ...filters, pagina: 1 });
		}
		setActionsDisabled(false);
		table ? setPageLoading(false) : setIsLoadingDestinat(false);
	};

	//---------------------------------

	const [isLoadingMore, setIsLoadingMore] = useState(false);

	const loadMoreDestinatarios = async (newPage) => {
		if (
			currentFilter &&
			currentFilter.pagina &&
			newPage > currentFilter.pagina &&
			!isLoadingMore
		) {
			setIsLoadingMore(true);
			const data = await eventsApi.getReceiversEntradas({
				...currentFilter,
				pagina: newPage,
				entradaId: parseInt(entradaData.id),
			});
			if (data && data.succeeded) {
				setDestinatariosList((prevList) => [
					...prevList,
					...data.data.receiverList,
				]);
				setCurrentFilter({ ...currentFilter, pagina: newPage });
				setIsLoadingMore(false);
			}
		}
	};

	const totalPages = Math.ceil((destinatariosTotalPage || 0) / 50);
	const currentPage = currentFilter?.pagina || 1;

	//---------------------------------

	const getDestinatariosModal = async (filters) => {
		setIsLoadingModal(true);

		const data = await eventsApi.getUninvitedGuestsEntrada({
			...filters,
			entradaId: parseInt(entradaData.id),
		});
		if (data && data.succeeded) {
			setDestinatariosModalList(data.data.receiverList);
			setDestinatariosModalTotalFilter(data.data.totalReceiverEntrada);
			setTagListModal(data.data.selectEtiquetas);
			setCompaniesModal(data.data.selectEmpresas);
			setOriginsModal(data.data.selectProcedencias);
		}

		setIsLoadingModal(false);
	};

	const toggleModalDestinatarios = () => {
		showDestinatariosModal && resetFilters();
		showDestinatariosModal && getDestinatarios(currentFilter, true);
		showDestinatariosModal && setTempDestinatariosSelected([]);
		setShowDestinatariosModal(!showDestinatariosModal);
	};

	const resetFilters = () => {
		setDisableHandleAdd(false);
	};

	const handleFilterDestinatarios = (values) => {
		getDestinatarios(values, false);
	};

	const handleFilterModalDestinatarios = (values) => {
		getDestinatariosModal(values);
	};

	const handleAddDestinatarios = async () => {
		setDisableHandleAdd(true);
		setPageLoading(true);
		const updatedDest = _.unionBy(
			noFilterDestinatList,
			tempDestinatariosSelected,
			'invitadoId'
		);
		const arrayDest = updatedDest.map((inv) => {
			return { eventoInvitadoId: inv.invitadoId, emailEnvioEntrada: inv.emailEntrada };
		});
		const data = await eventsApi.setEntradaReceivers({
			arrDestinatariosDetalle: arrayDest,
			eventoId: eventDataSimple.id,
			id: entradaData.id,
		});

		if (data && data.succeeded) {
			toggleModalDestinatarios();
		} else {
			notify.error('Ha ocurrido un error añadiendo los destinatarios.');
		}
		setPageLoading(false);
		setDisableHandleAdd(false);
	};

	const restoreDestinatarios = async () => {
		setActionsDisabled(true);
		setPageLoading(true);
		const arrayDest = destinatariosGuardadosList.map((inv) => {
			return { eventoInvitadoId: inv.invitadoId, emailEnvioEntrada: inv.emailEntrada };
		});
		const data = await eventsApi.setEntradaReceivers({
			arrDestinatariosDetalle: arrayDest,
			eventoId: eventDataSimple.id,
			id: entradaData.id,
		});

		if (data && data.succeeded) {
			setTempDestinatariosSelected([]);
			reloadEntrada(true);
		} else {
			notify.error('Ha ocurrido un error cancelando los cambios.');
		}
		setPageLoading(false);
		setActionsDisabled(false);
	};

	const handleDeleteDestinatario = (destID) => {
		MySwal.fire({
			title: '¿Quieres eliminar el destinatario?',
			cancelButtonText: 'Conservar',
			showCancelButton: true,
			confirmButtonText: 'Eliminar',
			focusConfirm: false,
			focusCancel: false,
			reverseButtons: true,
			buttonsStyling: false,
			customClass: {
				confirmButton: 'btn-prim-dark btn',
				cancelButton: 'btn-prim-light btn',
			},
		}).then(async (result) => {
			if (result && result.isConfirmed) {
				setActionsDisabled(true);
				setPageLoading(true);

				const cleanShow = destinatariosList.filter(
					(item) => item.invitadoId !== destID
				);
				const cleanFilter = noFilterDestinatList.filter(
					(item) => item.invitadoId !== destID
				);
				const arrayDest = cleanShow.map((inv) => {
					return {
						eventoInvitadoId: inv.invitadoId,
						emailEnvioEntrada: inv.email,
					};
				});

				const data = await eventsApi.setEntradaReceivers({
					arrDestinatariosDetalle: arrayDest,
					eventoId: eventDataSimple.id,
					id: entradaData.id,
				});

				if (data && data.succeeded) {
					setDestinatariosList(cleanShow);
					setNoFilterDestinatList(cleanFilter);
					setDestinatariosTotal(destinatariosTotal - 1);
				} else {
					notify.error('Ha ocurrido un error eliminando el destinatario.');
				}
				setPageLoading(false);
				setActionsDisabled(false);
			}
		});
	};

	useEffect(() => {
		setAllDestSelected(
			destinatariosModalTotalFilter !== 0 &&
				destinatariosModalTotalFilter ===
					_.countBy(destinatariosModalList, (o) => o.isSelected === true).true
				? true
				: false
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [destinatariosModalList]);

	const handleSelectDestinatario = (index, dest, status) => {
		let arraySelecteds = tempDestinatariosSelected;
		status
			? arraySelecteds.push({ ...dest, eventoId: parseInt(eventDataSimple.id) })
			: _.remove(arraySelecteds, (item) => item.invitadoId === dest.invitadoId);
		setTempDestinatariosSelected(arraySelecteds);

		let newState = [...destinatariosModalList];
		newState[index].isSelected = status;
		setDestinatariosModalList(newState);
	};

	const handleSelectAllDestinatarios = (status) => {
		const updatedContacts = destinatariosModalList.map((inv) => ({
			...inv,
			isSelected: status,
			entradaId: parseInt(entradaData.id),
		}));
		setDestinatariosModalList(updatedContacts);

		status
			? setTempDestinatariosSelected(
					_.unionBy(tempDestinatariosSelected, updatedContacts, 'invitadoId')
			  )
			: setTempDestinatariosSelected(
					tempDestinatariosSelected.filter(
						(ar) =>
							!updatedContacts.find((rm) => rm.invitadoId === ar.invitadoId)
					)
			  );
	};

	const updateSelectedMail = async (destIndex, destVals, destValIx, invtId) => {

		const updatedDest = destinatariosList.map((elem, ix) =>
			ix === destIndex
				? {
						...elem,
						email: destVals.email,
						emailEntrada: destVals.email,
						dataEmails: elem.dataEmails.map((dm, ixdm) =>
							ixdm === destValIx
								? { ...dm, emailEntrada: true }
								: { ...dm, emailEntrada: false }
						),
				  }
				: elem
		);
		setDestinatariosList(updatedDest);

		//update noFilter list
		const updatedNoFilter = noFilterDestinatList.map((elem) =>
			elem.invitadoId === invtId
				? {
						...elem,
						email: destVals.email,
						emailEntrada: destVals.email,
				  }
				: elem
		);
		setNoFilterDestinatList(updatedNoFilter);

		//update ws para busqueda temporal
		const data = await eventsApi.changeEmailTemp({
			emailEnvioEntrada: destVals.email,
			eventoInvitadoId: invtId,
		});
		if (data && !data.succeeded) {
			notify.error('Ha ocurrido un error actualizando el destinatario.');
		}
	};

	//unificar envios
	const [showModalUnify, setShowModalUnify] = useState(false);

	const togleModalUnify = () => {
		setUnifyContacts(entradaData.unificarEnvio);
		setShowModalUnify(!showModalUnify);
	};

	const handleUnifyContacts = async (unifyUpdate) => {
		formikRef.current &&
			formikRef.current.setFieldValue('unificarEnvio', unifyUpdate);
		formikRef.current && formikRef.current.submitForm();
	};

	return (
		<>
			<Formik
				innerRef={formikRef}
				enableReinitialize
				initialValues={entradaData == null ? newEntrada : entradaData}
				validateOnChange={false}
				validateOnBlur={false}
				onSubmit={(values, actions) => handleSubmitForm(values, actions)}
			>
				{({
					values,
					setFieldValue,
					isSubmitting,
					validateForm,
					submitForm,
				}) => {
					return (
						<FullLateralModal
							title={entradaData ? entradaData.nombre : 'Nueva Entrada'}
							isOpen={modalNuevaOpen}
							toggleModal={closeModalMenu}
							showBackButton
							nextButtonFn={() => {
								if (entradaStep === 0) {
									if (entradaIsReadyToSend(values)) {
										handleSendEntrada(
											values.totalDestinatariosUpdate,
											values.id
										);
									} else {
										notify.error(
											'Debes revisar que todos los pasos estén completados.'
										);
									}
								} else if (entradaStep === 1) {
									//email
									submitForm().then(() => {
										reloadEntrada(true);
									});
								} else if (entradaStep === 2) {
									//destinatarios
									const arrayDest = noFilterDestinatList.map((inv) => {
										return {
											eventoInvitadoId: inv.invitadoId,
											emailEnvioEntrada: inv.email,
										};
									});
									formikRef.current.setFieldValue(
										'arrDestinatariosDetalle',
										arrayDest
									);
									submitForm().then(() => {
										reloadEntrada(true);
										setActionsDisabled(false);
									});
								}
							}}
							hideCancel={entradaStep === 0 ? true : false}
							hiddenActions={entradaStep === 3 ? true : false}
							cancelButtonFn={() => {
								if (entradaStep === 1) {
									//email
									reloadEntrada(true);
								} else if (entradaStep === 2) {
									//destinatarios
									restoreDestinatarios();
								}
							}}
							isEditing
							isConfirming={entradaStep === 0 ? true : false}
							confirmLabel="Enviar entrada"
							disableConfirm={values?.id === 0 ? true : false}
							stepBackFn={() => {
								if (entradaStep > 0) {
									if (entradaData.fechaEnvio) {
										closeModalMenu();
									} else if (entradaStep === 1) {
										reloadEntrada(true);
									} else if (entradaStep === 2) {
										restoreDestinatarios();
									} else {
										setEntradaStep(0);
									}
								} else {
									closeModalMenu();
								}
							}}
							disabledButtons={isSubmitting ? true : actionsDisabled}
						>
							<>
								{pageLoading && <LoadingLayer visible={pageLoading} />}
								<Form autoComplete="off">
									<>
										{entradaStep === 0 ? (
											<ModalBody>
												<div className="nueva-invitacion">
													{!changeName ? (
														<Row className="mb-4 px-3 d-flex align-items-center">
															<Col md="11">
																{entradaData
																	? entradaData.nombre
																	: 'Entrada sin nombre'}
															</Col>
															<Col md="1" className="text-end">
																<Button
																	className="btn-prim-light btn-icon"
																	onClick={() => {
																		changeAsunto || changeDe || changeCompany
																			? setchangeName(false)
																			: setchangeName(true);
																	}}
																>
																	<i className="fticon-edit"></i>
																</Button>
															</Col>
														</Row>
													) : (
														<Row className="mb-3 pr-4">
															<Col md="10">
																<Field
																	name="nombre"
																	label="Nombre"
																	placeholder="Entrada sin nombre"
																	className="nueva-entrada-nombre"
																	component={InputForm}
																/>
															</Col>
															<Col md="2" className="text-end">
																<Button
																	className="btn-prim-close btn-icon"
																	onClick={() => setchangeName(false)}
																>
																	<i className="fticon-close tick-close"></i>
																</Button>
																<Button
																	className="btn-prim-check btn-icon"
																	onClick={() => {
																		submitForm();
																		setchangeName(false);
																	}}
																>
																	<i className="fticon-tick tick-close"></i>
																</Button>
															</Col>
														</Row>
													)}

													<Row
														className={
															values.id === 0
																? 'deshabilitar-campos box-list'
																: 'box-list'
														}
													>
														<Col md="12" className="mb-3">
															<WhiteBox>
																<Row>
																	<Col md="1" className="text-center">
																		<i
																			className={
																				entradaData && entradaData.companyLogo
																					? 'fticon-confirm check-invitacion cambio-check'
																					: 'fticon-confirm check-invitacion'
																			}
																		></i>
																	</Col>
																	<Col md="2">
																		<strong>Empresa:</strong>
																	</Col>
																	<Col md="9">
																		{!changeCompany ? (
																			<Row>
																				<Col md="11">
																					{entradaData &&
																					entradaData.companyLogo
																						? values.companyLogo?.label
																						: '¿Qué empresa organiza el evento?'}
																				</Col>
																				<Col md="1">
																					<Button
																						className="btn-prim-light btn-icon"
																						onClick={() => {
																							changeName ||
																							changeDe ||
																							changeAsunto
																								? setChangeCompany(false)
																								: setChangeCompany(true);
																						}}
																					>
																						<i className="fticon-edit"></i>
																					</Button>
																				</Col>
																			</Row>
																		) : (
																			<Row>
																				<Col md="5">
																					<Field
																						label="Empresa"
																						placeholder="Empresa"
																						options={companyLogos || []}
																						name="companyLogo"
																						component={InputSelect}
																						findByValue
																						help={
																							'No hay empresas disponibles.'
																						}
																					/>
																				</Col>

																				<Col md="7" className="text-end">
																					<Button
																						className="btn-prim-close btn-icon"
																						onClick={() =>
																							setChangeCompany(false)
																						}
																					>
																						<i className="fticon-close tick-close"></i>
																					</Button>
																					<Button
																						className="btn-prim-check btn-icon"
																						onClick={() => {
																							submitForm();
																							setChangeCompany(false);
																						}}
																					>
																						<i className="fticon-tick tick-close"></i>
																					</Button>
																				</Col>
																			</Row>
																		)}
																	</Col>
																</Row>
															</WhiteBox>
														</Col>
														<Col md="12" className="mb-3">
															<WhiteBox>
																<Row>
																	<Col md="1" className="text-center">
																		<i
																			className={
																				entradaData && entradaData.asunto
																					? 'fticon-confirm check-invitacion cambio-check'
																					: 'fticon-confirm check-invitacion'
																			}
																		></i>
																	</Col>
																	<Col md="2">
																		<strong>Asunto:</strong>
																	</Col>
																	<Col md="9">
																		{!changeAsunto ? (
																			<Row>
																				<Col md="11">
																					{entradaData && entradaData.asunto
																						? values.asunto
																						: '¿Cuál es el asunto de la entrada?'}
																				</Col>
																				<Col md="1">
																					<Button
																						className="btn-prim-light btn-icon"
																						onClick={() => {
																							changeName ||
																							changeDe ||
																							changeCompany
																								? setchangeAsunto(false)
																								: setchangeAsunto(true);
																						}}
																					>
																						<i className="fticon-edit"></i>
																					</Button>
																				</Col>
																			</Row>
																		) : (
																			<Row>
																				<Col md="7">
																					<Field
																						name="asunto"
																						label="Asunto"
																						placeholder="¿Cuál es el asunto de la entrada?"
																						component={InputForm}
																					/>
																				</Col>
																				<Col md="3">
																					<button
																						type="button"
																						className="variable-nombre p-0"
																						onClick={() =>
																							setFieldValue(
																								'asunto',
																								values.asunto
																									? `${values.asunto} [name]`
																									: '[name]'
																							)
																						}
																					>
																						<span>
																							Insertar variable nombre
																						</span>
																					</button>
																				</Col>
																				<Col md="2" className="text-center">
																					<Button
																						className="btn-prim-close btn-icon"
																						onClick={() =>
																							setchangeAsunto(false)
																						}
																					>
																						<i className="fticon-close tick-close"></i>
																					</Button>
																					<Button
																						className="btn-prim-check btn-icon"
																						onClick={() => {
																							submitForm();
																							setchangeAsunto(false);
																						}}
																					>
																						<i className="fticon-tick tick-close"></i>
																					</Button>
																				</Col>
																			</Row>
																		)}
																	</Col>
																</Row>
															</WhiteBox>
														</Col>
														<Col md="12" className="mb-3">
															<WhiteBox>
																<Row>
																	<Col md="1" className="text-center">
																		<i
																			className={
																				entradaData &&
																				entradaData.sendFromNombre
																					? 'fticon-confirm check-invitacion cambio-check'
																					: 'fticon-confirm check-invitacion'
																			}
																		></i>
																	</Col>
																	<Col md="2">
																		<strong>De:</strong>
																	</Col>
																	<Col md="9">
																		{!changeDe ? (
																			<Row>
																				<Col md="11">
																					{entradaData &&
																					entradaData.sendFromNombre ? (
																						<>
																							{entradaData.sendFromNombre}
																							<span className="bullet-sep"></span>
																							{entradaData.sendFromEmail}
																						</>
																					) : (
																						'¿Quién envía la entrada?'
																					)}
																				</Col>
																				<Col md="1">
																					<Button
																						className="btn-prim-light btn-icon"
																						onClick={() => {
																							changeName ||
																							changeAsunto ||
																							changeCompany
																								? setchangeDe(false)
																								: setchangeDe(true);
																						}}
																					>
																						<i className="fticon-edit"></i>
																					</Button>
																				</Col>
																			</Row>
																		) : (
																			<Row>
																				<Col md="5">
																					<Field
																						type="text"
																						name="sendFromNombre"
																						label="Nombre"
																						placeholder="Nombre"
																						component={InputForm}
																					/>
																				</Col>
																				<Col md="5">
																					<Field
																						type="text"
																						name="sendFromEmail"
																						label="Email"
																						placeholder="Email"
																						component={InputForm}
																					/>
																				</Col>
																				<Col md="2" className="text-center">
																					<Button
																						className="btn-prim-close btn-icon"
																						onClick={() => setchangeDe(false)}
																					>
																						<i className="fticon-close tick-close"></i>
																					</Button>
																					<Button
																						className="btn-prim-check btn-icon"
																						onClick={() => {
																							submitForm();
																							setchangeDe(false);
																						}}
																					>
																						<i className="fticon-tick tick-close"></i>
																					</Button>
																				</Col>
																			</Row>
																		)}
																	</Col>
																</Row>
															</WhiteBox>
														</Col>
														<Col md="12" className="mb-3">
															<WhiteBox>
																<Row>
																	<Col md="1" className="text-center">
																		<i
																			className={
																				entradaData &&
																				templateIsReadyToSend(values)
																					? 'fticon-confirm check-invitacion cambio-check'
																					: 'fticon-confirm check-invitacion'
																			}
																		></i>
																	</Col>
																	<Col md="2">
																		<strong>Email</strong>
																	</Col>
																	<Col md="6">Contenido del email</Col>
																	<Col md="3" className="text-end">
																		<Button
																			className="btn-prim-light"
																			type="button"
																			onClick={() => setEntradaStep(1)}
																		>
																			Editar email
																		</Button>
																	</Col>
																</Row>
															</WhiteBox>
														</Col>
														<Col md="12" className="mb-3">
															<WhiteBox>
																<Row>
																	<Col md="1" className="text-center">
																		<i
																			className={
																				entradaData &&
																				entradaData.totalDestinatariosUpdate !==
																					0
																					? 'fticon-confirm check-invitacion cambio-check'
																					: 'fticon-confirm check-invitacion'
																			}
																		></i>
																	</Col>
																	<Col md="2">
																		<strong>Destinatarios</strong>
																	</Col>
																	<Col md="6">
																		{entradaData &&
																		entradaData.totalDestinatariosUpdate !== 0
																			? `${entradaData.totalDestinatariosUpdate} destinatarios seleccionados`
																			: '¿A quién se enviará la entrada?'}
																	</Col>
																	<Col md="3" className="text-end">
																		<Button
																			className="btn-prim-light"
																			onClick={() => setEntradaStep(2)}
																		>
																			Seleccionar destinarios
																		</Button>
																	</Col>
																</Row>
															</WhiteBox>
														</Col>
														{/* TBD CONDICIONAL UNIFY */}
														{entradaData &&
															entradaData.unificarEnvio &&
															entradaData.unificarEnvio.length > 0 && (
																<Col md="12" className="mb-3">
																	<WhiteBox>
																		<Row>
																			<Col md="1" className="text-center">
																				<i
																					className={
																						entradaData &&
																						entradaData.totalDestinatariosUpdate !==
																							0
																							? 'fticon-confirm check-invitacion cambio-check'
																							: 'fticon-confirm check-invitacion'
																					}
																				></i>
																			</Col>
																			<Col md="2">
																				<strong>Unificar envío</strong>
																			</Col>
																			<Col md="8" className="col-text">
																				<p>
																					Se han detectado emails coincidentes
																				</p>
																				<span>
																					<i className="fticon-alert"></i>
																				</span>
																			</Col>
																			<Col md="1" className="text-end">
																				<Button
																					className="btn-prim-light btn-icon"
																					onClick={() => togleModalUnify()}
																				>
																					<i className="fticon-edit"></i>
																				</Button>
																			</Col>
																		</Row>
																	</WhiteBox>
																</Col>
															)}
														{/* TBD CONDICIONAL UNIFY */}
													</Row>
												</div>
											</ModalBody>
										) : entradaStep === 1 ? (
											<div className="preview-invitacion">
												<ModalBody>
													<Row>
														<Col md="3" className="bg-white">
															<Row className="preview-data-mail">
																<Col md="12">
																	<div className="tit">Texto principal</div>
																</Col>
																<Col md="12">
																	<Field
																		label="Texto"
																		placeholder="Texto"
																		name="textoPrincipal"
																		component={InputForm}
																		type="textarea"
																		className="textarea"
																		rows="4"
																	/>
																	<Button
																		className="variable-nombre"
																		onClick={() =>
																			setFieldValue(
																				'textoPrincipal',
																				values.textoPrincipal
																					? `${values.textoPrincipal} [name]`
																					: '[name]'
																			)
																		}
																	>
																		Insertar variable Nombre
																	</Button>
																</Col>
																<Col md="12">
																	<div className="tit">Imagen</div>
																</Col>
																<Col md="12">
																	<Field
																		name="documento"
																		component={InputImage}
																		accept={IMAGE_TYPES_ALLOWED}
																		image={
																			values.base64
																				? values.base64
																				: values.urlImagen
																				? values.urlImagen
																				: null
																		}
																		field_base64="base64"
																		field_filename={`filename`}
																	/>
																</Col>
																<Col md="12">
																	<div className="tit mb-4 mt-5">
																		Información a mostrar
																	</div>
																	<Field
																		component={InputForm}
																		baseLabel
																		type="switch"
																		label={
																			'Mostrar hora de apertura y límite de acceso'
																		}
																		name="showEntryHour"
																	/>
																	<Field
																		component={InputForm}
																		baseLabel
																		type="switch"
																		label={
																			'Mostrar número de fila y butaca en la entrada'
																		}
																		name="showRowSeat"
																	/>
																</Col>
																<Col md="12">
																	<div className="tit">Firma</div>
																</Col>
																<Col md="12">
																	<Field
																		label="Remitente"
																		placeholder="Remitente"
																		name="firmaCargo"
																		component={InputForm}
																	/>
																</Col>
																<Col md="12">
																	<Field
																		label="Teléfono"
																		placeholder="Teléfono"
																		name="firmaTelefono"
																		component={InputForm}
																	/>
																</Col>
																<Col md="12">
																	<div className="tit">Texto legal</div>
																</Col>
																<Col md="12">
																	<Field
																		label="Texto"
																		placeholder="Texto"
																		name="firmaTextoLegal"
																		component={InputForm}
																		type="textarea"
																		className="textarea"
																		rows="8"
																	/>
																</Col>
																<Col md="12" className="mb-5">
																	<Button
																		className="btn btn-sec-light"
																		onClick={() =>
																			toggleModalEntradaPreview(
																				values.showEntryHour,
																				values.showRowSeat
																			)
																		}
																	>
																		Previsualizar entrada PDF
																	</Button>
																</Col>
															</Row>
														</Col>
														<Col md="9">
															<InvitationMailPreview
																invitationEvent={eventDataSimple}
																invitationData={values}
																previewEntrada
															/>
														</Col>
													</Row>
												</ModalBody>
											</div>
										) : entradaStep === 2 ? (
											<div ref={destinatariosPortalRef}></div>
										) : (
											<div className="dashboard-sent-invitation">
												<EntradaSentDetails
													entradaData={entradaData}
													eventDataSimple={eventDataSimple}
												/>
											</div>
										)}
									</>
								</Form>

								{showModalPDF && (
									<EntradaPreviewModal
										isOpen={showModalPDF}
										toggleModal={toggleModalEntradaPreview}
										previewData={eventDataSimple}
										showDates={boolEntryHour}
										showSitting={boolRoomSeat}
									/>
								)}

								{showDestinatariosModal && (
									<LateralModal
										size="xl"
										isOpen={showDestinatariosModal}
										toggleModal={toggleModalDestinatarios}
										title="Invitados confirmados"
										noCloseOutside
									>
										<ModalBody className="event-contacts-modal">
											<Row>
												<Col md="12">
													<ContactsFilter
														tagList={tagListModal}
														companies={companiesModal}
														submitFilter={handleFilterModalDestinatarios}
														fromOrigin={originsModal}
														vipFilter={filterVipModal}
														orderFilter={filterOrderModal}
														entradaFilter={filterEntradaModal}
														disabled={isLoadingModal}
													/>
												</Col>
											</Row>

											<Row className="table-header pb-3">
												<Col md="4" className="col-star">
													<TableVipFilter
														filterVip={filterVipModal}
														setFilterVip={setFilterVipModal}
													/>
													<TableRowOrder
														selectedOp={filterOrderModal}
														selectFn={setFilterOrderModal}
													/>
												</Col>
												<Col md="3">Empresa</Col>
												<Col md="3">Email</Col>
												<Col md="1">
													<TableRowFilter
														titleRow="Entrada"
														options={defEntradasFilter}
														selectedOption={filterEntradaModal}
														selectFn={setFilterEntradaModal}
														wNullReturn
													/>
												</Col>
												<Col md="1">
													<CheckboxNF
														value={allDestSelected}
														count={tempDestinatariosSelected.length}
														onChange={(e) =>
															handleSelectAllDestinatarios(e.target.checked)
														}
													/>
												</Col>
											</Row>
											<Row className="contact-list">
												<Col md="12">
													{isLoadingModal ? (
														<TableLoader />
													) : (
														<div
															className={
																destinatariosModalList.length > 5
																	? 'scrollable-contacts'
																	: 'scrollable-contacts compact'
															}
														>
															{destinatariosModalList &&
															destinatariosModalList.length > 0 ? (
																destinatariosModalList.map(
																	(destinat, index) => {
																		return (
																			<Row
																				key={index}
																				className="table-row w-proc"
																			>
																				<Col md="4" className="col-star">
																					<div className="vip">
																						{destinat.destacado && (
																							<i className="fticon-star-solid"></i>
																						)}
																					</div>
																					<div className="name">
																						{destinat.nombreContacto}
																						{destinat.procedencia && (
																							<div className="sub-proc">
																								{destinat.procedencia}
																							</div>
																						)}
																					</div>
																				</Col>

																				<Col md="3">
																					{destinat.nombreEmpresa &&
																						destinat.nombreEmpresa}
																				</Col>
																				<Col md="3">
																					<div className="mail-list">
																						{destinat.email && destinat.email}
																					</div>
																				</Col>

																				<Col md="1">
																					{destinat.estadoEntrada && (
																						<div
																							className={`icon-table icon-entrada st-${noSpaceString(
																								destinat.estadoEntrada
																							)}`}
																						>
																							<i className="fticon-ticket"></i>
																						</div>
																					)}
																				</Col>
																				<Col md="1" className="text-center">
																					<CheckboxNF
																						value={
																							_.find(
																								tempDestinatariosSelected,
																								{
																									invitadoId:
																										destinat.invitadoId,
																								}
																							)
																								? true
																								: false
																						}
																						onChange={(e) =>
																							handleSelectDestinatario(
																								index,
																								destinat,
																								e.target.checked
																							)
																						}
																					/>
																				</Col>
																			</Row>
																		);
																	}
																)
															) : (
																<Col md="12" className="text-center py-5">
																	Sin resultados
																</Col>
															)}
														</div>
													)}
												</Col>
											</Row>
										</ModalBody>
										<ModalFooter>
											<Button
												className="btn-sec-dark"
												onClick={handleAddDestinatarios}
												disabled={
													isLoadingModal
														? true
														: tempDestinatariosSelected.length > 0
														? disableHandleAdd
														: true
												}
											>
												Añadir destinatarios
											</Button>
										</ModalFooter>
									</LateralModal>
								)}

								{showModalUnify && (
									<EntradasUnifyModal
										isOpen={showModalUnify}
										toggleModal={togleModalUnify}
										unifyContacts={unifyContacts}
										setUnifyContacts={setUnifyContacts}
										unifyUpdate={handleUnifyContacts}
									/>
								)}
							</>
						</FullLateralModal>
					);
				}}
			</Formik>
			<Portal container={destinatariosPortalRef.current}>
				<div className="destinatarios-invitacion">
					<ModalBody>
						<div className="container">
							<Row>
								<Col md="12">
									<div className="invitados-tit">
										<h3 className="mb-0">
											Destinatarios
											<span className="badge-count">
												<span>{destinatariosTotal}</span>
											</span>
										</h3>
										{destinatariosTotal > 0 && (
											<Button
												className="btn-prim-light mt-1"
												onClick={toggleModalDestinatarios}
											>
												Añadir destinatarios
											</Button>
										)}
									</div>
									{destinatariosTotal > 0 ? (
										<WhiteBox containerClass="full-box invitados-list">
											<Row>
												<Col md="12">
													<ContactsFilter
														tagList={tagList}
														companies={companies}
														submitFilter={handleFilterDestinatarios}
														fromOrigin={origins}
														vipFilter={filterVip}
														orderFilter={filterOrder}
														entradaFilter={filterEntrada}
														disabled={isLoadingDestinat}
													/>
												</Col>
											</Row>
											<Row className="table-header pb-3">
												<Col md="3" className="col-star">
													<TableVipFilter
														filterVip={filterVip}
														setFilterVip={setFilterVip}
													/>
													<TableRowOrder
														selectedOp={filterOrder}
														selectFn={setFilterOrder}
													/>
												</Col>
												<Col md="3">Empresa</Col>
												<Col md="4">Email</Col>

												<Col md="2">
													<TableRowFilter
														titleRow="Entrada"
														options={defEntradasFilter}
														selectedOption={filterEntrada}
														selectFn={setFilterEntrada}
														wNullReturn
													/>
												</Col>
											</Row>
											<Row>
												<Col md="12">
													<div className="contact-list">
														{isLoadingDestinat ? (
															<TableLoader />
														) : destinatariosList &&
														  destinatariosList.length > 0 ? (
															<div className="scrollable-contacts">
																<InfiniteScroll
																	pageStart={0}
																	loadMore={() =>
																		loadMoreDestinatarios(currentPage + 1)
																	}
																	hasMore={currentPage < totalPages}
																	useWindow={false}
																	loader={
																		<div key={0} style={{ clear: 'both' }}>
																			<TableLoader size="xs" />
																		</div>
																	}
																>
																	{destinatariosList &&
																		destinatariosList.map((destinat, index) => {
																			return (
																				<Row key={index} className="table-row">
																					<Col md="3" className="col-star">
																						<div className="vip">
																							{destinat.destacado && (
																								<i className="fticon-star-solid"></i>
																							)}
																						</div>
																						<div className="name">
																							{destinat.nombreContacto}
																							{destinat.procedencia && (
																								<div className="sub-proc">
																									{destinat.procedencia}
																								</div>
																							)}
																						</div>
																					</Col>

																					<Col md="3">
																						{destinat.nombreEmpresa &&
																							destinat.nombreEmpresa}
																					</Col>
																					<Col md="4">
																						<div className="mail-edit">
																							{destinat.email && destinat.email}
																							{destinat.dataEmails &&
																								destinat.dataEmails.length >
																									1 && (
																									<TableDropEdit
																										selectFn={
																											updateSelectedMail
																										}
																										invitadoId={
																											destinat.invitadoId
																										}
																										ixDest={index}
																										options={
																											destinat.dataEmails
																										}
																									/>
																								)}
																						</div>
																					</Col>

																					<Col md="2" className="col-opts">
																						{destinat.estadoEntrada ? (
																							<div
																								className={`icon-table icon-entrada st-${noSpaceString(
																									destinat.estadoEntrada
																								)}`}
																							>
																								<i className="fticon-ticket"></i>
																							</div>
																						) : (
																							<div></div>
																						)}

																						<Button
																							className="btn-sec-link btn-icon"
																							onClick={() =>
																								handleDeleteDestinatario(
																									destinat.invitadoId
																								)
																							}
																						>
																							<i className="fticon-close"></i>
																						</Button>
																					</Col>
																				</Row>
																			);
																		})}
																</InfiniteScroll>
															</div>
														) : (
															<div className="no-results py-5">
																No se han encontrado resultados.
															</div>
														)}
													</div>
												</Col>
											</Row>
										</WhiteBox>
									) : (
										<WhiteBox containerClass="full-box empty">
											<div className="no-results">
												<h3>No hay destinatarios</h3>
												<p>
													Añade destinatarios a esta invitación de tu lista de
													invitados
												</p>
												<Button
													className="btn-prim-light mt-1"
													onClick={toggleModalDestinatarios}
												>
													Añadir destinatarios
												</Button>
											</div>
										</WhiteBox>
									)}
								</Col>
							</Row>
						</div>
					</ModalBody>
				</div>
			</Portal>
		</>
	);
};

export default EventDBnuevaEntrada;
