//notifications
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


export const unsavedChangesModal = (cancelConfirmFn) => {
   const MySwal = withReactContent(Swal);
	MySwal.fire({
		title: 'Cambios sin guardar',
		text: 'Si cierras la ventana se perderán los cambios.',
		cancelButtonText: 'Cancelar',
		showCancelButton: true,
		confirmButtonText: 'Cerrar',
		focusConfirm: false,
		focusCancel: false,
		reverseButtons: true,
		buttonsStyling: false,
		customClass: {
			confirmButton: 'btn-prim-dark btn',
			cancelButton: 'btn-prim-light btn',
		},
	}).then((result) => {
		if (result && result.isConfirmed) cancelConfirmFn();
	});
};


export const bulkUpdateOptions = (cancelConfirmFn, subText, prodExcept = false) => {
   const MySwal = withReactContent(Swal);
	MySwal.fire({
		title: 'Edición múltiple',
		text: `${subText} se aplicará a todos los invitados seleccionados${prodExcept ? ' excepto a las productoras':'.'}`,
		cancelButtonText: 'Cancelar',
		showCancelButton: true,
		confirmButtonText: 'Continuar',
		focusConfirm: false,
		focusCancel: false,
		reverseButtons: true,
		buttonsStyling: false,
		customClass: {
			confirmButton: 'btn-prim-dark btn',
			cancelButton: 'btn-prim-light btn',
		},
	}).then((result) => {
		if (result && result.isConfirmed) cancelConfirmFn();
	});
};

export const deleteItemModal = (elimObject, cancelConfirmFn) => {
   const MySwal = withReactContent(Swal);
	MySwal.fire({
		title: `¿Quieres eliminar ${elimObject}?`,
		cancelButtonText: 'Conservar',
		showCancelButton: true,
		confirmButtonText: 'Eliminar',
		focusConfirm: false,
		focusCancel: false,
		reverseButtons: true,
		buttonsStyling: false,
		customClass: {
			confirmButton: 'btn-prim-dark btn',
			cancelButton: 'btn-prim-light btn',
		},
	}).then((result) => {
		if (result && result.isConfirmed) cancelConfirmFn();
	});
};

export const eventRoomUpdateModal = (confirmAction) => {
   const MySwal = withReactContent(Swal);
	MySwal.fire({
		title: 'Cambios en las salas',
		text: 'Alguna de las salas modificadas tiene actualmente butacas ocupadas. Si continúas, tendrás que volver a sentar a los asistentes en otra sala.',
		cancelButtonText: 'Cancelar',
		showCancelButton: true,
		confirmButtonText: 'Continuar',
		focusConfirm: false,
		focusCancel: false,
		reverseButtons: true,
		buttonsStyling: false,
		customClass: {
			confirmButton: 'btn-prim-dark btn',
			cancelButton: 'btn-prim-light btn',
		},
	}).then((result) => {
		if (result && result.isConfirmed) confirmAction();
	});
};
