import React, { useRef } from 'react';
import _ from 'lodash';

import { Col, Row, Button } from 'reactstrap';

//componentes
import InputForm from 'components/common/forms/InputForm';
import InputSelect from 'components/common/forms/InputSelect';


//forms
import { Formik, Form, Field } from 'formik';

//notifications
import { unsavedChangesModal, bulkUpdateOptions } from 'components/common/modals/SwalModals';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const InvitadoBulkUpdate = ({
	isOpen,
	invitadosSelected = [],
	procedencias = [],
	closeModalFn,
	tipoAnunciante,
	saveModalFn,
	deleteModalFn
}) => {
	const formikRef = useRef();

	const initialBulkData = {
		sitting: null,
		photocall: null,
		esVIP: null,
		procedencia: null,
	};

	const closeModal = () => {
		if (formikRef.current && formikRef.current.dirty) {
			unsavedChangesModal(() => closeModalFn());
		} else {
			closeModalFn();
		}
	};

	const hasProductoraInSelected = () => {
		return invitadosSelected.find((inv) =>
			tipoAnunciante
				? inv.contactoAnunciante.esPersona === false
				: inv.contacto.esPersona === false
		)
			? true
			: false;
	};

	const evalFieldUpdate = (action, setFieldFn) => {
		if (action === 'sitting') {
			bulkUpdateOptions(
				setFieldFn,
				'Al modificar el Sitting, este',
				hasProductoraInSelected()
			);
		} else if (action === 'photocall') {
			bulkUpdateOptions(
				setFieldFn,
				'Al modificar el Photocall, este',
				hasProductoraInSelected()
			);
		} else if (action === 'esVIP') {
			bulkUpdateOptions(setFieldFn, 'Al modificar el Destacado, este');
		} else if (action === 'procedencia') {
			bulkUpdateOptions(setFieldFn, 'Al modificar la Procedencia, esta');
		}
	};

   const bulkUpdateContacts = (values) => {

		const formProcedencia = values.procedencia
			? values.procedencia.__isNew__
				? _.omit(values.procedencia, ['value'])
				: values.procedencia
			: null;

		const updateInvitados = {
			updateSitting: !_.isNull(values.sitting) ? true : false,
			sittingValue: values.sitting || false,
			updatePhotocall: !_.isNull(values.photocall) ? true : false,
			photoCallValue: values.photocall || false,
			updateVip: !_.isNull(values.esVIP) ? true : false,
			vipValue: values.esVIP || false,
			updateProcedencia: !_.isNull(values.procedencia) ? true : false,
			procedenciaValue: formProcedencia || null,
			invitados: invitadosSelected.map((inv) => (inv.id)),
		};


      saveModalFn(updateInvitados);
	}

	const MySwal = withReactContent(Swal);
	const removeGuestBulk = (invitadosSelected) => {
		MySwal.fire({
			title: `Estás a punto de eliminar a ${invitadosSelected.length} invitados`,
			text: 'Solo se podrán eliminar aquellos que no tengan una invitación enviada.',
			cancelButtonText: 'Conservar',
			showCancelButton: true,
			confirmButtonText: 'Eliminar',
			focusConfirm: false,
			focusCancel: false,
			reverseButtons: true,
			buttonsStyling: false,
			customClass: {
				confirmButton: 'btn-prim-dark btn',
				cancelButton: 'btn-prim-light btn',
			},
		}).then(async (result) => {
			if (result && result.isConfirmed) {
				const deleteInvitados = {
					invitados: invitadosSelected.map((inv) => (inv.id)),
				};
				deleteModalFn(deleteInvitados);
			}
		});
	};

	return (
		<Formik
			innerRef={formikRef}
			enableReinitialize
			initialValues={initialBulkData}
			validateOnChange={false}
			validateOnBlur={false}
			onSubmit={bulkUpdateContacts}
		>
			{({ values, setFieldValue, dirty }) => {
				return (
					<div className={`overlay-bottom-dashboard ${isOpen ? 'show' : ''}`}>
						<Form autoComplete="off">
							<div className="modal-header">
								<div className="title">
									Edición múltiple
									<span>{invitadosSelected.length} invitados</span>
								</div>

								{dirty ? (
									<div className="action-buttons">
										<Button
											type="button"
											onClick={closeModal}
											className="btn-sec-light"
										>
											Cancelar
										</Button>
										<Button type="submit" className="btn-sec-dark">
											Guardar
										</Button>
									</div>
								) : (
									<div className="close" onClick={closeModal}>
										<i className="fticon-close"></i>
									</div>
								)}
							</div>
							<div className="modal-body">
								<Row className="mt-2 mx-0">
									<Col md="2">
										<div className="input-row">
											<div className="icon">
												<i className="fticon-sitting"></i>
											</div>
											{!_.isNull(values.sitting) ? (
												<div className="field">
													<Field
														component={InputForm}
														baseLabel
														type="switch"
														label={'Sitting'}
														name="sitting"
														reverse
													/>
												</div>
											) : (
												<div
													className="editing"
													onClick={() => evalFieldUpdate('sitting', () => setFieldValue('sitting', false))}
												>
													<div>Sitting</div>
													<div>
														<i className="fticon-edit"></i>
													</div>
												</div>
											)}
										</div>
									</Col>
									<Col md="2">
										<div className="input-row">
											<div className="icon">
												<i className="fticon-camera"></i>
											</div>
											{!_.isNull(values.photocall) ? (
												<div className="field">
													<Field
														component={InputForm}
														baseLabel
														type="switch"
														label={'Photocall'}
														name="photocall"
														reverse
													/>
												</div>
											) : (
												<div
													className="editing"
													onClick={() => evalFieldUpdate('photocall', () => setFieldValue('photocall', false))}
												>
													<div>Photocall</div>
													<div>
														<i className="fticon-edit"></i>
													</div>
												</div>
											)}
										</div>
									</Col>
									<Col md="2">
										<div className="input-row">
											<div className="icon">
												<i className="fticon-star"></i>
											</div>
											{!_.isNull(values.esVIP) ? (
												<div className="field">
													<Field
														component={InputForm}
														baseLabel
														type="switch"
														label={'Destacado'}
														name="esVIP"
														reverse
													/>
												</div>
											) : (
												<div
													className="editing"
													onClick={() => evalFieldUpdate('esVIP', () => setFieldValue('esVIP', false))}
												>
													<div>Destacado</div>
													<div>
														<i className="fticon-edit"></i>
													</div>
												</div>
											)}
										</div>
									</Col>
									<Col md="2">
										{!_.isNull(values.procedencia) ? (
											<Field
														label="Procedencia"
														placeholder="Procedencia"
														name="procedencia"
														options={procedencias}
														component={InputSelect}
														className="input-create w-arrow"
														menuPlacement="top"
														createLabel={'Crear nueva'}
														isClearable
														canCreate
													/>
										) : (
											<div className="input-row">
												<div className="icon">
													<i className="fticon-user"></i>
												</div>
												<div
													className="editing"
													onClick={() => evalFieldUpdate('procedencia', () => setFieldValue('procedencia', false))}
												>
													<div>Procedencia</div>
													<div>
														<i className="fticon-edit"></i>
													</div>
												</div>
											</div>
										)}
									</Col>
									<Col md="4">
										<Button
											onClick={() => { removeGuestBulk(invitadosSelected);}}
											className="btn-prim-light"
										>
											Eliminar invitados
										</Button>
									</Col>
								</Row>
							</div>
						</Form>
					</div>
				);
			}}
		</Formik>
	);
};

export default InvitadoBulkUpdate;
