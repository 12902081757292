import { LogLevel } from '@azure/msal-browser';
import { mainMsalInstance } from '..';
import CryptoJS from 'crypto-js';
import moment from 'moment';

// TOKENS definidos en entorno
const clientId = process.env.REACT_APP_MSAL_CLIENTID ?? 'd1e555f3-51a6-4e26-8062-ad50869f3b4b';
const tenantId = process.env.REACT_APP_MSAL_TENANTID ?? '9c72562b-8077-4c0f-8444-ae87fb3a1fbb';

//navs
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0;

export const msalConfig = {
	auth: {
		clientId: clientId,
		authority: `https://login.microsoftonline.com/${tenantId}`,
		//redirectUri: MsalRedirectUri,
	},
	cache: {
		cacheLocation: 'sessionStorage',
		storeAuthStateInCookie: isIE || isEdge || isFirefox,
	},
	system: {
		loggerOptions: {
			logLevel: LogLevel.Error,
			loggerCallback: (level, message, containsPii) => {
				if (containsPii) {
					return;
				}
				switch (level) {
					case LogLevel.Error:
						console.error(message);
						return;
					case LogLevel.Info:
						console.info(message);
						return;
					case LogLevel.Verbose:
						console.debug(message);
						return;
					case LogLevel.Warning:
						console.warn(message);
						return;
					default:
						return;
				}
			},
		},
	},
};

export const loginRequest = {
	scopes: ['openid', 'profile', 'User.Read'],
};


export const handleMsalLogin = (msalInstance) => {
	msalInstance.loginRedirect(loginRequest).catch(e => {
	  console.log('onLogin error', e);
 });
}

export const handleMsalLogout = (msalInstance) => {
	msalInstance.logoutRedirect({
			 postLogoutRedirectUri: '/',
		});
}

export const getAuthUsermail = () => {
	return mainMsalInstance.getActiveAccount().username || null;
}

export const getAuthHeaders = (isJson = true) => {
	let userName = mainMsalInstance.getActiveAccount().username || null;
	let token = CryptoJS.SHA256(`${userName}_JwtGoEvent_${moment().format("DDMMYYYY")}`);
	let userHeaders = { ADUserName: userName, JwtGoEvent: token };
	return isJson
		? { ...userHeaders, 'Content-Type': 'application/json' }
		: userHeaders;
}
