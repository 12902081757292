import React from 'react';

//componentes
import { Row, ModalBody } from 'reactstrap';
import LateralModal from 'components/common/modals/LateralModal';

//helpers
import { spanishDate } from 'utils/helpers';

const EntradaPreviewModal = ({
	isOpen,
	toggleModal,
	previewData,
	showDates = true,
	showSitting = true,
}) => {
	return (
		<LateralModal
			size="xm"
			isOpen={isOpen}
			toggleModal={toggleModal}
			title="Previsualización entrada PDF"
		>
			<ModalBody className="event-entrada-modal">
				<Row
					className="row-entrada"
					style={{ backgroundImage: `url(${previewData?.urlImagen})` }}
				>
					<div className="poster-entrada">
						<div className="spacer-entrada"></div>

						<div className="contenedor-entrada-qr">
							<div className="info-entrada">
								<div className="detalles">
									<p className="fecha-entrada">
										{spanishDate(previewData?.fechaEvento, true)}
									</p>
									<p className="titulo-entrada">{previewData?.nombreEvento}</p>
									<p className="lugar-entrada">
										{previewData?.eventSite}
										<br />
										{previewData?.direccionEvento}
									</p>
									{showDates ? (
										<div className="div-entrada">
											<div className="sticker sticker-mustard-bg me-3">
												<strong>{previewData?.horaEvento}h</strong>
											</div>
											<span className="texto-entrada">
												Apertura de puertas a las {previewData?.horaApertura}h.
												<br />
												No se podrá acceder despues de las{' '}
												{previewData?.horaLimite}h.
											</span>
										</div>
									) : (
										<div className="div-entrada">
											<div className="sticker sticker-mustard-bg me-3">
												<strong>
													Hora del evento {previewData?.horaEvento}h
												</strong>
											</div>
										</div>
									)}
								</div>
								<div className="qr">
									<i className="fticon-qr" />
								</div>
							</div>

							<div className="line"></div>

							<div className={`seat-info ${showSitting ? 'sitting':'no-sitting'}`}>
								<div className="site sala">
									<div className="tit">Sala</div>
									<div className="box">[nombre_sala]</div>
								</div>
								<div className="site zona">
									<div className="tit">Zona</div>
									<div className="box">[nombre_zona]</div>
								</div>
								{showSitting && (
									<>
										<div className="site fila">
											<div className="tit">Fila</div>
											<div className="box">[num]</div>
										</div>
										<div className="site butaca">
											<div className="tit">Butaca</div>
											<div className="box">[num]</div>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</Row>
			</ModalBody>
		</LateralModal>
	);
};

export default EntradaPreviewModal;
