import React, { useRef, useState, useEffect } from 'react';
import _, { isEqual } from 'lodash';

import { Col, Row, ModalBody, Button, ModalFooter } from 'reactstrap';

//componentes
import LateralModal from 'components/common/modals/LateralModal';
import FullLateralModal from 'components/common/modals/FullLateralModal';
import WhiteBox from 'components/common/elems/WhiteBox';
import TableLoader from 'components/loaders/TableLoader';
import Medal from 'components/common/elems/Medal';
import Sticker from 'components/common/elems/Sticker';
import CheckboxNF from 'components/common/forms/CheckboxNF';
import ContactsForm from 'pages/contacts/ContactsForm';
import ContactsFilter from 'pages/contacts/ContactsFilter';

//services
import * as sitesApi from 'api/sitesApi';
import * as contactsApi from 'api/contactsApi';
import * as eventsApi from 'api/eventsApi';

//forms
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { IMAGE_TYPES_ALLOWED, isBeforeTime } from 'utils/helpers';
import InputForm from 'components/common/forms/InputForm';
import InputSelect from 'components/common/forms/InputSelect';
import InputUploadBtn from 'components/common/forms/InputUploadBtn';
import InputTime from 'components/common/forms/InputTime';
import InputDate from 'components/common/forms/InputDate';

//notifications
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as notify from 'utils/notify';
import { eventRoomUpdateModal } from 'components/common/modals/SwalModals';

//images
import ImageAdvert from 'css/images/event-ad.svg';
import ImagePrem from 'css/images/event-premier.svg';
import StackedCheckbox from 'components/common/forms/StackedCheckbox';
import InputImageUpload from 'components/common/forms/InputImageUpload';

const EventsForm = ({
	modalBarOpen,
	toggleModalBar,
	modalData,
	defModelForm = {},
	handleSubmitForm,
	handleUploadExcel,
	uploadExcelId,
	handleDownloadExcel,
	setModalData,
	isEditing,
}) => {
	const formikRef = useRef();
	const MySwal = withReactContent(Swal);

	const [eventStep, setEventStep] = useState(isEditing ? 1 : 0);
	const [tempRoomList, setTempRoomList] = useState([]);
	const [sitesAndRooms, setSitesAndRooms] = useState([]);
	const [tipoAnunciante, setTipoAnunciante] = useState(false);

	//invitados
	const [showModalInvitados, setShowModalInvitados] = useState(false);
	const [isLoadingContacts, setIsLoadingContacts] = useState(true);
	const [tagList, setTagList] = useState([]);
	const [companies, setCompanies] = useState([]);
	const [currentFilter, setCurrentFilter] = useState({});
	const [currentFilterAdvert, setCurrentFilterAdvert] = useState({});

	const [allSelected, setAllSelected] = useState(false);

	const [backInvitados, setBackInvitados] = useState([]);
	const [totalFiltered, setTotalFiltered] = useState(0);
	const [invitadosList, setInvitadosList] = useState([]);
	const [invitadosListFiltered, setInvitadosListFiltered] = useState([]);
	const [tempInvitadosSelected, setTempInvitadosSelected] = useState([]);

	//nuevo contacto
	const [showNewContactModal, setShowNewContactModal] = useState(false);

	useEffect(() => {
		getSitesAndRooms();
		getTags();
		getCompanies();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (showModalInvitados) tipoAnunciante ? getExcelContacts() : getContacts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showModalInvitados]);

	const getTags = async () => {
		const data = await contactsApi.getTags();
		setTagList(data.data);
	};

	const getCompanies = async () => {
		const data = await contactsApi.getCompanies();
		setCompanies(data.data);
	};

	const getSitesAndRooms = async () => {
		const data = await sitesApi.getSitesAndRooms();
		setSitesAndRooms(data.data);

		if (isEditing) {
			const findRoom = data.data
				? data.data.find((option) =>
						_.isEqual(option.value, modalData.lugarOrigenId)
				  )
				: null;
			findRoom && setTempRoomList(findRoom.rooms);
			findRoom && setModalData({ ...modalData, eventoLugar: findRoom });
		}
	};

	const closeModalMenu = () => {
		if (formikRef.current && formikRef.current.dirty) {
			MySwal.fire({
				title: 'Cambios sin guardar',
				text: 'Si cierras la ventana se perderán los cambios.',
				cancelButtonText: 'Cancelar',
				showCancelButton: true,
				confirmButtonText: 'Cerrar',
				focusConfirm: false,
				focusCancel: false,
				reverseButtons: true,
				buttonsStyling: false,
				customClass: {
					confirmButton: 'btn-prim-dark btn',
					cancelButton: 'btn-prim-light btn',
				},
			}).then((result) => {
				if (result && result.isConfirmed) {
					toggleModalBar();
					setTempRoomList(null);
					setEventStep(1);
				}
			});
		} else {
			toggleModalBar();
		}
	};

	const validationSchema = Yup.object().shape({
		nombre: Yup.string().required('REQUIRED'),
		fechaEventoDate: Yup.date()
			.min(new Date(), 'La fecha del evento debe ser mayor que hoy.')
			.required('REQUIRED'),
		horaEvento: Yup.string()
			.required('REQUIRED')
			.length(5)
			.matches(
				/(\d){2}:(\d){2}/,
				'La hora del evento debe tener este formato "HH:MM"'
			),
		fechaLimiteConfirmDate: isEditing
			? Yup.date()
					.max(
						Yup.ref('fechaEventoDate'),
						'La fecha límite de confirmación debe ser menor que la fecha del evento.'
					)
					.required('REQUIRED')
			: Yup.date()
					.min(
						new Date(),
						'La fecha límite de confirmación debe ser mayor que la fecha actual.'
					)
					.max(
						Yup.ref('fechaEventoDate'),
						'La fecha límite de confirmación debe ser menor que la fecha del evento.'
					)
					.required('REQUIRED'),
		horaLimiteConfirm: Yup.string()
			.required('REQUIRED')
			.length(5)
			.matches(
				/(\d){2}:(\d){2}/,
				'La hora límite de confirmación debe tener este formato "HH:MM"'
			)
			.when(['fechaEventoDate', 'fechaLimiteConfirmDate'], {
				is: (fechaEventoDate, fechaLimiteConfirmDate) =>
					isEqual(fechaEventoDate, fechaLimiteConfirmDate),
				then: Yup.string()
					.required('REQUIRED')
					.test(
						'horaLimiteConfirm_test',
						'La hora límite de confirmación debe ser menor a la hora del evento.',
						function (value) {
							const { horaEvento } = this.parent;
							return isBeforeTime(value, horaEvento);
						}
					),
			}),
		lugarOrigenId: Yup.number()
			.nullable()
			.required('Debes seleccionar un lugar para el evento.'),

		salasIdArray: Yup.array().when('lugarOrigenId', {
			is: (lugarOrigenId) => {
				return lugarOrigenId === null ? false : true;
			},
			then: Yup.array().min(
				1,
				'Debes seleccionar al menos una sala para el evento.'
			),
		}),
		eventoLugar: Yup.object().shape({
			value: Yup.number().required('REQUIRED'),
		}),
		urlImagen: Yup.mixed().required(
			'Debes seleccionar una imagen para el evento.'
		),

		horaApertura: Yup.string()
			.required('REQUIRED')
			.length(5)
			.matches(
				/(\d){2}:(\d){2}/,
				'La hora de apertura debe tener este formato "HH:MM"'
			),
		// .test(
		// 	'horaApertura_test',
		// 	'La hora de apertura debe ser menor a la hora del evento.',
		// 	function (value) {
		// 		const { horaEvento } = this.parent;
		// 		return isBeforeTime(value, horaEvento);
		// 	}
		// ),
		horaLimiteAcceso: Yup.string()
			.required('REQUIRED')
			.length(5)
			.matches(
				/(\d){2}:(\d){2}/,
				'La hora limite de acceso debe tener este formato "HH:MM"'
			),
		// .test(
		// 	'horaLimiteAcceso_test',
		// 	'La hora limite de acceso debe ser menor a la hora del evento.',
		// 	function (value) {
		// 		const { horaEvento } = this.parent;
		// 		return isBeforeTime(value, horaEvento);
		// 	}
		// ),
		horaPhotoCall: Yup.string()
			.length(5)
			.matches(
				/(\d){2}:(\d){2}/,
				'La hora del photo call debe tener este formato "HH:MM"'
			),
		defaultInvitations: Yup.number().min(1, 'Al menos debe haber una invitación por invitado.').required('REQUIRED'),
	});

	const validateFormChanges = (values, submitForm) => {
		if (!values.canEditPlace) {
			let originalRooms = values.salasId.split(',').map(Number);
			let selectedRooms = values.salasIdArray;
			let roomChange = _.difference(originalRooms, selectedRooms);

			if (roomChange.length > 0) {
				let salasOcupadas = roomChange.find(
					(room) => values.salasIdOcupadas[room]
				);
				salasOcupadas ? eventRoomUpdateModal(submitForm) : submitForm();
			} else {
				submitForm();
			}
		} else {
			submitForm();
		}
	};

	//------------------ INVITADOS

	const [disabledHandleAdd, setDisabledHandleAdd] = useState(false);

	useEffect(() => {
		setAllSelected(
			totalFiltered !== 0 &&
				totalFiltered ===
					_.countBy(backInvitados, (o) => o.isSelected === true).true
				? true
				: false
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [backInvitados]);

	useEffect(() => {
		setInvitadosListFiltered(invitadosList);
		const arrayIdInvitados = invitadosList.map((inv) =>
			tipoAnunciante ? { contactoAnuncianteId: inv.id } : { contactoId: inv.id }
		);
		formikRef.current.setFieldValue('invitados', arrayIdInvitados);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invitadosList]);

	useEffect(() => {
		uploadExcelId && toggleModalInvitados();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uploadExcelId]);

	const toggleModalInvitados = () => {
		setTempInvitadosSelected([]);
		setCurrentFilter({});
		setAllSelected(false);
		setTotalFiltered(0);
		setDisabledHandleAdd(false);
		setShowModalInvitados(!showModalInvitados);
	};

	const handleSubmitContactsFilter = (filters) => {
		tipoAnunciante ? getExcelContacts(filters) : getContacts(filters);
	};

	const getContacts = async (filters = currentFilter) => {
		setIsLoadingContacts(true);

		const data = await eventsApi.getContacts(filters);
		const filtered = data.data.contactsList.filter(
			(obj1) => !invitadosList.find((obj2) => obj1.id === obj2.id)
		);
		setTotalFiltered(filtered.length);
		setBackInvitados(filtered);
		setCurrentFilter(filters);

		setIsLoadingContacts(false);
	};

	const getExcelContacts = async (filters = currentFilterAdvert) => {
		setIsLoadingContacts(true);

		let filtersId = { ...filters, archivoId: uploadExcelId };
		const data = await eventsApi.getContactsAdversiter(filtersId);
		setTotalFiltered(data.data.contactsList.length);
		setBackInvitados(data.data.contactsList);
		setCurrentFilterAdvert(filtersId);

		setIsLoadingContacts(false);
	};

	const selectAllContacts = async (status) => {
		const updatedContacts = backInvitados.map((inv) => ({
			...inv,
			isSelected: status,
		}));
		setBackInvitados(updatedContacts);

		status
			? setTempInvitadosSelected(
					_.unionBy(tempInvitadosSelected, updatedContacts, 'id')
			  )
			: setTempInvitadosSelected(
					tempInvitadosSelected.filter(
						(ar) => !updatedContacts.find((rm) => rm.id === ar.id)
					)
			  );
	};

	const selectContact = (index, contact, state) => {
		let arraySelecteds = tempInvitadosSelected;
		state
			? arraySelecteds.push(contact)
			: _.remove(arraySelecteds, (item) => item.id === contact.id);
		setTempInvitadosSelected(arraySelecteds);

		let newState = [...backInvitados];
		newState[index].isSelected = state;
		setBackInvitados(newState);
	};

	const handleAddInvitados = () => {
		if (tempInvitadosSelected.length > 0) {
			setDisabledHandleAdd(true);
			setInvitadosList(_.unionBy(invitadosList, tempInvitadosSelected, 'id'));
			toggleModalInvitados();
		} else {
			const MySwal = withReactContent(Swal);
			MySwal.fire({
				title: 'No has seleccionado ningún invitado',
				text: '¿Quieres continuar?',
				cancelButtonText: 'Cancelar',
				showCancelButton: true,
				confirmButtonText: 'Continuar',
				focusConfirm: false,
				focusCancel: false,
				reverseButtons: true,
				buttonsStyling: false,
				customClass: {
					confirmButton: 'btn-prim-dark btn',
					cancelButton: 'btn-prim-light btn',
				},
			}).then((result) => {
				if (result && result.isConfirmed) toggleModalInvitados();
			});
		}
	};

	const handleRemoveInvitado = (ctcId) => {
		const clean = invitadosList.filter((item) => item.id !== ctcId);
		setInvitadosList(clean);
	};

	const initialFilter = {
		nombreCompleto: '',
		etiquetaId: [],
		etiqueta: {},
		empresaId: 0,
		empresa: {},
		procedenciaId: 0,
		procedencia: {},
	};

	useEffect(() => {
		//reset filters
		eventStep && eventStep === 2 && handleFilterInList(initialFilter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [eventStep]);

	const handleFilterInList = (filters) => {
		let filterList = [];
		let emptySearch = true;

		//filtra por nombre-apellidos
		if (!_.isEmpty(filters.nombreCompleto)) {
			emptySearch = false;
			const acentos = { á: 'a', é: 'e', í: 'i', ó: 'o', ú: 'u' };
			filterList = invitadosList.filter(
				(item) =>
					item.nombre
						?.split('')
						.map((letra) => acentos[letra] || letra)
						.join('')
						.toString()
						.toLowerCase()
						.includes(
							filters.nombreCompleto
								.split('')
								.map((letra) => acentos[letra] || letra)
								.join('')
								.toString()
								.toLowerCase()
						) ||
					item.apellidos
						?.split('')
						.map((letra) => acentos[letra] || letra)
						.join('')
						.toString()
						.toLowerCase()
						.includes(
							filters.nombreCompleto
								.split('')
								.map((letra) => acentos[letra] || letra)
								.join('')
								.toString()
								.toLowerCase()
						)
			);
		}

		//filtra por etiquetas
		if (filters.etiquetaId.length > 0) {
			let tempFilter =
				filterList.length > 0 && !emptySearch ? filterList : invitadosList;

			tempFilter = tempFilter.filter((item) => {
				if (item.etiquetasContactos.length > 0) {
					var tempStik = item.etiquetasContactos.filter((etiFilter) =>
						_.find(filters.etiquetaId, { value: etiFilter.etiquetaId })
					);
					return tempStik.length > 0 ? true : false;
				} else {
					return false;
				}
			});

			emptySearch = false;
			filterList = tempFilter;
		}

		//filtra por ID de empresa
		if (filters.empresaId && filters.empresaId !== 0) {
			let tempFilter =
				filterList.length > 0 && !emptySearch ? filterList : invitadosList;
			tempFilter = tempFilter.filter(
				(item) => item.empresa?.value === filters.empresaId
			);

			emptySearch = false;
			filterList = tempFilter;
		}

		if (emptySearch) filterList = invitadosList;
		setInvitadosListFiltered(filterList);
	};

	const toggleNewContactModal = () => {
		setShowNewContactModal(!showNewContactModal);
	};

	const handleCreateNewContact = async (values, formProps) => {
		const formEmpresa = values.empresa
			? values.empresa.__isNew__
				? _.omit(values.empresa, ['value'])
				: values.empresa
			: null;

		const formEtiquetas = values.etiquetasContactos
			? values.etiquetasContactos.map((item) =>
					item.__isNew__ ? _.omit(item, ['value']) : item
			  )
			: null;

		const updatedValues = {
			...values,
			empresa: formEmpresa,
			etiquetas: formEtiquetas,
			etiquetasContactos: [],
			archivoId: uploadExcelId,
		};

		const data = await eventsApi.AddContactAdvertiser(updatedValues);
		if (data && data.succeeded) {
			if (values.reload) {
				formProps.resetForm({ values: newContactModel });
			} else {
				setShowNewContactModal(false);
			}

			setInvitadosList([...invitadosList, data.data]);
			notify.success('El contacto se ha creado correctamente.');
		} else {
			notify.error(`Ha ocurrido un error creando el contacto. ${data.message}`);
		}
	};

	const newContactModel = {
		nombre: '',
		esPersona: true,
		emails: [{ mail: '' }],
		etiquetas: [],
		etiquetasContactos: [],
		empresa: null,
		deletedMails: [],
	};

	return (
		<Formik
			innerRef={formikRef}
			enableReinitialize
			initialValues={modalData && modalData.id ? modalData : defModelForm}
			validationSchema={validationSchema}
			validateOnChange={false}
			validateOnBlur={false}
			onSubmit={(values, actions) => handleSubmitForm(values, actions)}
		>
			{({ values, setFieldValue, isSubmitting, validateForm, submitForm }) => {
				return (
					<FullLateralModal
						title={
							modalData && modalData.id ? modalData.nombre : 'Nuevo evento'
						}
						isOpen={modalBarOpen}
						toggleModal={closeModalMenu}
						disabledButtons={isSubmitting}
						hiddenActions={eventStep === 0 ? true : false}
						stepBackFn={() =>
							eventStep > 0 ? setEventStep(eventStep - 1) : closeModalMenu()
						}
						showBackButton={isEditing ? false : true}
						isEditing={isEditing}
						cancelButtonFn={closeModalMenu}
						nextButtonFn={() => {
							validateForm().then((res) => {
								if (!_.isEmpty(res)) {
									_.values(res).map(
										(err) => err !== 'REQUIRED' && notify.error(err)
									);
								} else if (eventStep === 1 && !isEditing) {
									setEventStep(2);
								} else {
									isEditing
										? validateFormChanges(values, submitForm)
										: submitForm();
								}
							});
						}}
						isConfirming={eventStep === 2 ? true : false}
					>
						<>
							<Form autoComplete="off" className="event-form">
								{eventStep === 1 ? (
									<>
										<ModalBody>
											<div className="step-1">
												<Row>
													<Col md="4">
														<Field
															name="urlImagen"
															component={InputImageUpload}
															accept={IMAGE_TYPES_ALLOWED}
															image={
																values.base64
																	? values.base64
																	: values.urlImagen
																	? values.urlImagen
																	: null
															}
															field_base64="base64"
															field_filename={`filename`}
														/>
													</Col>
													<Col md="8">
														<WhiteBox>
															<Row>
																<Col md="12">
																	<Field
																		label="Nombre del evento*"
																		placeholder="Nombre del evento*"
																		name="nombre"
																		component={InputForm}
																		disabled={
																			isEditing ? !modalData.canEditData : false
																		}
																	/>
																</Col>
																<Col md="12">
																	<Field
																		label="Descripción"
																		placeholder="Descripción"
																		name="descripcion"
																		component={InputForm}
																		type="textarea"
																		className="textarea"
																		rows="4"
																	/>
																</Col>
																<Col md="6">
																	<Field
																		label="Fecha del evento*"
																		placeholder="Fecha del evento*"
																		name="fechaEventoDate"
																		type="date"
																		component={InputDate}
																		disabled={
																			isEditing ? !modalData.canEditData : false
																		}
																		autoFill
																	/>
																</Col>
																<Col md="6">
																	<Field
																		label="Hora del evento*"
																		placeholder="Hora del event*"
																		name="horaEvento"
																		component={InputTime}
																		disabled={
																			isEditing ? !modalData.canEditData : false
																		}
																		isClearable
																	/>
																</Col>
																<Col md="12">
																	<h4>Acceso al evento</h4>
																</Col>
																<Col md="4">
																	<Field
																		label="PhotoCall"
																		placeholder="PhotoCall"
																		name="horaPhotoCall"
																		component={InputTime}
																		isClearable
																	/>
																</Col>
																<Col md="4">
																	<Field
																		label="Apertura*"
																		placeholder="Apertura*"
																		name="horaApertura"
																		component={InputTime}
																		isClearable
																	/>
																</Col>
																<Col md="4">
																	<Field
																		label="Límite*"
																		placeholder="Límite*"
																		name="horaLimiteAcceso"
																		component={InputTime}
																		isClearable
																	/>
																</Col>
																<Col md="12">
																	<h4>Lugar del evento</h4>
																</Col>
																<Col md="12">
																	<Field
																		label="Lugar*"
																		placeholder="Lugar*"
																		name="eventoLugar"
																		component={InputSelect}
																		options={sitesAndRooms}
																		onChange={(e) => {
																			setFieldValue('eventoLugar', e);
																			setFieldValue('lugarOrigenId', e.value);
																			setFieldValue('salasIdArray', []);
																			setTempRoomList(e.rooms);
																		}}
																		canSearch
																		disabled={
																			isEditing
																				? !modalData.canEditPlace
																				: false
																		}
																	/>
																</Col>
																<Col md="12">
																	{tempRoomList &&
																		tempRoomList.length > 0 &&
																		tempRoomList.map((sala, index) => (
																			<Field
																				key={index}
																				label={sala.label}
																				name={sala.value}
																				reverse
																				fieldStack="salasIdArray"
																				component={StackedCheckbox}
																			/>
																		))}
																</Col>
																<Col md="12">
																	<h4>
																		Fecha límite para confirmar asistencia
																	</h4>
																</Col>
																<Col md="6">
																	<Field
																		label="Fecha*"
																		placeholder="Fecha*"
																		name="fechaLimiteConfirmDate"
																		type="date"
																		component={InputDate}
																		autoFill
																	/>
																</Col>
																<Col md="6">
																	<Field
																		label="Hora*"
																		placeholder="Hora*"
																		name="horaLimiteConfirm"
																		component={InputTime}
																		isClearable
																	/>
																</Col>
																<Col md="12">
																	<h4>
																		Invitaciones por defecto para cada invitado
																	</h4>
																</Col>
																<Col md="12">
																	<Field
																		label="Invitaciones*"
																		placeholder="Invitaciones*"
																		name="defaultInvitations"
																		component={InputForm}
																		type="number"
																		min="1"
																		disabled={
																			isEditing ? !modalData.canEditData : false
																		}
																	/>
																</Col>
																<Col md="12" className="check mt-3">
																	<Field
																		component={InputForm}
																		baseLabel
																		reverse
																		type="switch"
																		label={'Evento activo'}
																		name="activo"
																	/>
																</Col>
															</Row>
														</WhiteBox>
													</Col>
												</Row>
											</div>
										</ModalBody>
									</>
								) : eventStep === 2 ? (
									<>
										<ModalBody>
											<div className="step-2">
												<div className="container">
													<Row>
														<Col md="12">
															<div className="invitados-tit">
																<h3 className="mb-0">
																	Invitados
																	<span className="badge-count">
																		<span>{invitadosList.length}</span>
																	</span>
																</h3>
																{tipoAnunciante &&
																	(uploadExcelId ? (
																		<Button
																			className="btn-prim-light mt-1"
																			onClick={toggleNewContactModal}
																		>
																			Nuevo invitado
																		</Button>
																	) : (
																		<Button
																			className="btn-prim-light"
																			onClick={handleDownloadExcel}
																		>
																			Descargar plantilla excel
																		</Button>
																	))}
															</div>
															{invitadosList && invitadosList.length > 0 ? (
																<WhiteBox containerClass="full-box invitados-list">
																	<Row>
																		<Col md="12">
																			<ContactsFilter
																				tagList={tagList}
																				companies={companies}
																				submitFilter={handleFilterInList}
																			/>
																		</Col>
																	</Row>

																	<Row className="table-header pb-3">
																		<Col md="4">
																			<div className="spacer-medal"></div>
																			<div>Invitado</div>
																		</Col>
																		<Col className="text-center" md="4">
																			Etiqueta
																		</Col>
																		<Col md="3">Empresa</Col>
																		<Col md="1"></Col>
																	</Row>
																	<Row>
																		<Col md="12">
																			<div className="contact-list">
																				{invitadosListFiltered.length > 0 ? (
																					<div className="scrollable-contacts">
																						<div>
																							{invitadosListFiltered.map(
																								(contact, index) => {
																									const fullName =
																										contact.esPersona
																											? `${contact.nombre} ${
																													contact.apellidos
																														? contact.apellidos
																														: ''
																											  }`
																											: contact.nombre;

																									return (
																										<Row
																											key={index}
																											className="table-row"
																										>
																											<Col
																												md="4"
																												className="col-name"
																											>
																												<Medal
																													text={`${
																														contact.nombre
																													} ${
																														contact.apellidos
																															? contact.apellidos
																															: ''
																													}`}
																													color={
																														contact.color
																															.claseCss
																													}
																												/>
																												<div className="name">
																													{fullName}
																												</div>
																											</Col>
																											<Col
																												className="text-center"
																												md="4"
																											>
																												{contact.etiquetasContactos &&
																													contact
																														.etiquetasContactos
																														.length > 0 &&
																													contact.etiquetasContactos.map(
																														(etq, index) => (
																															<Sticker
																																key={index}
																																text={
																																	etq.etiqueta
																																		.label
																																}
																																color={
																																	etq.etiqueta
																																		.color
																																		.claseCss
																																}
																															/>
																														)
																													)}
																											</Col>

																											<Col md="3">
																												{contact.empresa &&
																													contact.empresa.label}
																											</Col>

																											<Col
																												md="1"
																												className="text-end"
																											>
																												<Button
																													className="btn-sec-link btn-icon"
																													onClick={(e) =>
																														handleRemoveInvitado(
																															contact.id
																														)
																													}
																												>
																													<i className="fticon-close"></i>
																												</Button>
																											</Col>
																										</Row>
																									);
																								}
																							)}
																						</div>
																					</div>
																				) : (
																					<div className="no-results py-5">
																						No se han encontrado resultados.
																					</div>
																				)}
																			</div>
																		</Col>
																	</Row>
																</WhiteBox>
															) : (
																<WhiteBox containerClass="full-box empty">
																	<div className="no-results">
																		<h3>No hay invitados</h3>

																		{!tipoAnunciante ? (
																			<>
																				<p>
																					Añade invitados de tu lista de
																					contactos
																				</p>
																				<Button
																					className="btn-prim-light mt-1"
																					onClick={toggleModalInvitados}
																				>
																					Añadir invitados
																				</Button>
																			</>
																		) : (
																			<>
																				<p>
																					Selecciona tu archivo de invitados
																				</p>
																				<Field
																					component={InputUploadBtn}
																					label={'Subir archivo de invitados'}
																					onChangeFn={handleUploadExcel}
																				/>
																			</>
																		)}
																	</div>
																</WhiteBox>
															)}
														</Col>
													</Row>
												</div>
											</div>
										</ModalBody>
									</>
								) : (
									<>
										<ModalBody>
											<div className="step-0">
												<Row>
													<Col md="6" className="options">
														<WhiteBox
															onClick={() => {
																setFieldValue('esAnunciante', true);
																setFieldValue('defaultInvitations', 1);
																setTipoAnunciante(true);
																setEventStep(1);
															}}
														>
															<img
																src={ImageAdvert}
																alt="Evento de anunciantes"
															/>
															<div>Anunciantes</div>
														</WhiteBox>
														<WhiteBox
															onClick={() => {
																setFieldValue('esAnunciante', false);
																setFieldValue('defaultInvitations', 2);
																setTipoAnunciante(false);
																setEventStep(1);
															}}
														>
															<img src={ImagePrem} alt="Evento de premiere" />
															<div>Première</div>
														</WhiteBox>
													</Col>
												</Row>
											</div>
										</ModalBody>
									</>
								)}
							</Form>

							{showNewContactModal && (
								<ContactsForm
									modalBarOpen={showNewContactModal}
									toggleModalBar={toggleNewContactModal}
									modalEdit={true}
									modalData={{}}
									defModelForm={newContactModel}
									handleSubmitForm={handleCreateNewContact}
									tagList={tagList}
									companies={companies}
									singleContact
								/>
							)}

							{showModalInvitados && (
								<LateralModal
									size="xl"
									isOpen={showModalInvitados}
									toggleModal={toggleModalInvitados}
									title="Contactos"
									noCloseOutside
								>
									<ModalBody className="event-contacts-modal">
										<Row>
											<Col md="12">
												<ContactsFilter
													tagList={tagList}
													companies={companies}
													submitFilter={handleSubmitContactsFilter}
													disabled={isLoadingContacts}
												/>
											</Col>
										</Row>

										<Row className="table-header pb-3">
											<Col md="4">
												<div className="spacer-medal"></div>
												<div>Invitado</div>
											</Col>
											<Col className="text-center" md="4">
												Etiqueta
											</Col>
											<Col md="3">Empresa</Col>
											<Col md="1">
												<CheckboxNF
													value={allSelected}
													count={tempInvitadosSelected.length}
													onChange={(e) => selectAllContacts(e.target.checked)}
												/>
											</Col>
										</Row>
										<Row className="contact-list">
											<Col md="12">
												{isLoadingContacts ? (
													<TableLoader />
												) : (
													<div
														className={
															backInvitados.length > 5
																? 'scrollable-contacts'
																: 'scrollable-contacts compact'
														}
													>
														{backInvitados && backInvitados.length > 0 ? (
															backInvitados.map((contact, index) => {
																const fullName = contact.esPersona
																	? `${contact.nombre} ${
																			contact.apellidos ? contact.apellidos : ''
																	  }`
																	: contact.nombre;

																return (
																	<Row key={index} className="table-row">
																		<Col md="4" className="col-name">
																			<Medal
																				text={`${contact.nombre} ${
																					contact.apellidos
																						? contact.apellidos
																						: ''
																				}`}
																				color={contact.color.claseCss}
																			/>
																			<div className="name">{fullName}</div>
																		</Col>
																		<Col className="text-center" md="4">
																			{contact.etiquetasContactos &&
																				contact.etiquetasContactos.length > 0 &&
																				contact.etiquetasContactos.map(
																					(etq, index) => (
																						<Sticker
																							key={index}
																							text={etq.etiqueta.label}
																							color={
																								etq.etiqueta.color.claseCss
																							}
																						/>
																					)
																				)}
																		</Col>

																		<Col md="3">
																			{contact.empresa && contact.empresa.label}
																		</Col>

																		<Col md="1" className="text-center">
																			<CheckboxNF
																				value={
																					_.find(tempInvitadosSelected, {
																						id: contact.id,
																					})
																						? true
																						: false
																				}
																				onChange={(e) =>
																					selectContact(
																						index,
																						contact,
																						e.target.checked
																					)
																				}
																			/>
																		</Col>
																	</Row>
																);
															})
														) : (
															<Col md="12" className="text-center py-5">
																Sin resultados
															</Col>
														)}
													</div>
												)}
											</Col>
										</Row>
									</ModalBody>
									<ModalFooter>
										<Button
											className="btn-sec-dark"
											onClick={handleAddInvitados}
											disabled={disabledHandleAdd}
										>
											Añadir invitados
										</Button>
									</ModalFooter>
								</LateralModal>
							)}
						</>
					</FullLateralModal>
				);
			}}
		</Formik>
	);
};

export default EventsForm;
