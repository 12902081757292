import { callApiGet, callApiPost, callApiPostUpload, callApiDownload } from './apiUtils';
const url_base = '/eventos';

export const getCurrentEvents = async (eventData) => {
	return await callApiPost(`${url_base}/getActiveEvents`, eventData);
};

export const getPastEvents = async (eventData) => {
	return await callApiPost(`${url_base}/getOldEvents`, eventData);
};

export const getEventSites = async () => {
	return await callApiGet(`${url_base}/getEventSites`);
};

export const setPremiere = async (eventData) => {
	return await callApiPostUpload(`${url_base}/setPremiere`, eventData);
};

export const setAdvertEvent = async (eventData) => {
	return await callApiPostUpload(`${url_base}/setEventAdvertiser`, eventData);
};

export const activateEvent = async (eventID) => {
	return await callApiGet(`${url_base}/activateEvent/${eventID}`);
};

export const uploadContacts = async (fileData) => {
	return await callApiPostUpload(`${url_base}/uploadContacts`, fileData);
};

export const getExcelTemplate = async () => {
	return await callApiGet(`${url_base}/getExcelTemplate`);
};


export const getContacts = async (filter) => {
	return await callApiPost(`${url_base}/getContacts`, filter);
};

export const AddContactAdvertiser = async (contactData) => {
	return await callApiPost(`${url_base}/AddContactAdvertiser`, contactData);
};

export const getContactsAdversiter = async (filter) => {
	return await callApiPost(`${url_base}/getContactsAdversiter`, filter);
};

export const changeEmailTemp = async (data) => {
	return await callApiPost(`${url_base}/changeEmailTemp`, data);
};


//dashboard event

export const getEvent = async (eventID) => {
	return await callApiGet(`${url_base}/getEvent/${eventID}`);
};

export const getEventSimple = async (eventID) => {
	return await callApiGet(`${url_base}/getEventSimple/${eventID}`);
};

export const getDataGuests = async (eventID) => {
	return await callApiGet(`${url_base}/getDataGuests/${eventID}`);
};

export const getGuests = async (filter) => {
	return await callApiPost(`${url_base}/getGuests`, filter);
};

export const getOrigins = async () => {
	return await callApiGet(`${url_base}/getOrigins`);
};

export const deleteEvento = async (eventID) => {
	return await callApiGet(`${url_base}/deleteEvento/${eventID}`);
};


//invitados event

export const deleteInvitado = async (guestID) => {
	return await callApiGet(`${url_base}/deleteGuest/${guestID}`);
};

export const getUninvitedContacts = async (filter) => {
	return await callApiPost(`${url_base}/getUninvitedContacts`, filter);
};

export const addGuestsFromContacts = async (contacts) => {
	return await callApiPost(`${url_base}/addGuestsFromContacts`, contacts);
};

export const addGuestAdvertiser = async (contact) => {
	return await callApiPost(`${url_base}/addGuestAdvertiser`, contact);
};

export const updateGuests = async (contactsData) => {
	return await callApiPost(`${url_base}/updateGuests`, contactsData);
};

export const updateBulkGuests = async (contactsData) => {
	return await callApiPost(`${url_base}/updateBulkGuests`, contactsData);
};

export const editContactAdvertiser = async (contactsData) => {
	return await callApiPost(`${url_base}/editContactAdvertiser`, contactsData);
};

export const downloadEntradasInvitado = async (invitData) => {
	return await callApiPost(`${url_base}/downloadEntradasInvitado`, invitData);
};

export const deleteBulkGuests = async (contactsData) => {
	return await callApiPost(`${url_base}/deleteBulkGuests`, contactsData);
};


// invitaciones event
export const getInvitation = async (invitationID) => {
	return await callApiGet(`${url_base}/getInvitation/${invitationID}`);
};

export const getInvitations = async (eventoId) => {
	return await callApiGet(`${url_base}/getInvitations/${eventoId}`);
};

export const cloneInvitation = async (invitationID, eventId) => {
	return await callApiGet(`${url_base}/cloneInvitation/${invitationID},${eventId}`);
};

export const setInvitation = async (invitationData) => {
	return await callApiPostUpload(`${url_base}/setInvitation`, invitationData);
};

export const setInvitationReceivers = async (invitationData) => {
	return await callApiPost(`${url_base}/setInvitationReceivers`, invitationData);
};

export const deleteInvitation = async (invitavionID) => {
	return await callApiGet(`${url_base}/deleteInvitation/${invitavionID}`);
};

export const getReceivers = async (filters) => {
	return await callApiPost(`${url_base}/getReceivers`, filters);
};

export const getUninvitedGuests = async (filters) => {
	return await callApiPost(`${url_base}/getUninvitedGuests`, filters);
};

export const sendInvitation = async (invitavionID) => {
	return await callApiGet(`${url_base}/sendInvitation/${invitavionID}`);
};

//planos event

export const getEventRooms = async (eventID) => {
	return await callApiGet(`${url_base}/getEventRooms/${eventID}`);
};

export const getDetailMap = async (mapData) => {
	return await callApiPost(`${url_base}/getDetailMap`, mapData);
};

export const setSection = async (section) => {
	return await callApiPost(`${url_base}/setSection`, section);
};

export const deleteSection = async (section) => {
	return await callApiPost(`${url_base}/deleteSection`, section);
};

export const updateSectionSeats = async (seatsData) => {
	return await callApiPost(`${url_base}/updateSectionSeats`, seatsData);
};

export const getAssistants = async (filters) => {
	return await callApiPost(`${url_base}/getAssistants`, filters);
};

export const getEventAreasMap = async (mapId) => {
	return await callApiGet(`${url_base}/getEventAreasMap/${mapId}`);
};

export const UpdateAssistantSitting = async (seatsData) => {
	return await callApiPost(`${url_base}/UpdateAssistantSitting`, seatsData);
};

export const updateAssistantsWithouthSitting = async (seatData) => {
	return await callApiPost(`${url_base}/updateAssistantsWithouthSitting`, seatData);
};

export const getNoSittingFreeAllRooms = async (eventID) => {
	return await callApiGet(`${url_base}/getNoSittingFreeAllRooms/${eventID}`);
};

export const updateBulkNoSitting = async (bulkData) => {
	return await callApiPost(`${url_base}/updateBulkNoSitting`, bulkData);
};

//select to filters
export const getEventTags = async (eventID) => {
	return await callApiGet(`${url_base}/getEventTags/${eventID}`);
};

export const getEventCompanies = async (eventID) => {
	return await callApiGet(`${url_base}/getEventCompanies/${eventID}`);
};

export const getEventOrigins = async (eventID) => {
	return await callApiGet(`${url_base}/getEventOrigins/${eventID}`);
};

//Entradas

export const getEntrada = async (eventID) => {
	return await callApiGet(`${url_base}/getEntrada/${eventID}`);
};

export const getEntradas = async (eventID) => {
	return await callApiGet(`${url_base}/getAllListEntradas/${eventID}`);
};

export const getReceiversEntradas = async (filters) => {
	return await callApiPost(`${url_base}/getReceiversEntradas`, filters);
};

export const cloneEntrada = async (entradaID, eventId) => {
	return await callApiGet(`${url_base}/cloneEntrada/${entradaID},${eventId}`);
};

export const deleteEntrada = async (entradaID) => {
	return await callApiGet(`${url_base}/deleteEntrada/${entradaID}`);
};

export const setEntrada = async (entradaData) => {
	return await callApiPostUpload(`${url_base}/setEntrada`, entradaData);
};

export const getUninvitedGuestsEntrada = async (filters) => {
	return await callApiPost(`${url_base}/getUninvitedGuestsEntrada`, filters);
};

export const setEntradaReceivers = async (entradaData) => {
	return await callApiPost(`${url_base}/setEntradaReceivers`, entradaData);
};

export const sendEntrada = async (entradaID) => {
	return await callApiGet(`${url_base}/sendEntrada/${entradaID}`);
};

//Notificaciones
export const getNotifications = async (eventoId) => {
	return await callApiGet(`${url_base}/getNotifications/${eventoId}`);
};

export const setNotification = async (notificationData) => {
	return await callApiPostUpload(`${url_base}/setNotification`, notificationData);
};

export const deleteNotification = async (notificationID) => {
	return await callApiGet(`${url_base}/deleteNotification/${notificationID}`);
};

export const cloneNotification = async (notificationID, eventId) => {
	return await callApiGet(`${url_base}/cloneNotification/${notificationID},${eventId}`);
};

export const getNotificationReceivers = async (filters) => {
	return await callApiPost(`${url_base}/getNotificationReceivers`, filters);
};

export const saveFileToAttachment = async (fileData) => {
	return await callApiPostUpload(`${url_base}/saveFileToAttachment`, fileData);
};

export const deleteFileToAttachment = async (atchData) => {
	return await callApiPost(`${url_base}/deleteFileToAttachment`, atchData);
};

export const getInvitedGuests = async (filters) => {
	return await callApiPost(`${url_base}/getInvitedGuests`, filters);
};

export const setNotificationReceivers = async (invitationData) => {
	return await callApiPost(`${url_base}/setNotificationReceivers`, invitationData);
};

export const getNotification = async (notificationID) => {
	return await callApiGet(`${url_base}/getNotification/${notificationID}`);
};

export const cancelEditNotification = async (notificationID) => {
	return await callApiGet(`${url_base}/cancelNotification/${notificationID}`);
};

export const sendNotification = async (notificationId) => {
	return await callApiGet(`${url_base}/sendNotification/${notificationId}`);
};

//download excel auth
export const downloadExcelAuth = async (fromUrl, fileName) => {
	return await callApiDownload(fromUrl, fileName);
};