import React, { useRef } from 'react';
import _ from 'lodash';

import {
	Col,
	Row,
	Button,
	ModalBody,
	ModalFooter,
	ButtonGroup,
} from 'reactstrap';

//componentes
import LateralModal from 'components/common/modals/LateralModal';
import InputForm from 'components/common/forms/InputForm';
import InputSelect from 'components/common/forms/InputSelect';
import InputSticker from 'components/common/forms/InputSticker';
import WhiteBox from 'components/common/elems/WhiteBox';
import Medal from 'components/common/elems/Medal';
import Sticker from 'components/common/elems/Sticker';

//forms
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';

//notifications
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const ContactsForm = ({
	modalBarOpen,
	toggleModalBar,
	modalEdit,
	modalData,
	defModelForm,
	handleSubmitForm,
	tagList,
	companies,
	toggleModalEdit,
	singleContact,
}) => {
	const formikRef = useRef();
	const MySwal = withReactContent(Swal);

	const validationSchema = Yup.object().shape({
		esPersona: Yup.boolean(),
		nombre: Yup.string().required(),
		apellidos: Yup.string().nullable().when('esPersona', {
			is: true,
			then: Yup.string(),
		}),
		emails: Yup.array()
			.of(
				Yup.object().shape({
					mail: Yup.string().email().required(),
				})
			)
			.min(1),
	});

	const deleteMailHelper = (mail, listDeleted = [], setFieldValue) => {
		if (mail && mail.id) {
			listDeleted.push(mail);
			const cleanList = _.uniqBy(listDeleted, 'mail');
			setFieldValue('deletedMails', cleanList);
		}
	};

	const closeModalMenu = () => {
		if (formikRef.current && formikRef.current.dirty) {
			MySwal.fire({
				title: 'Cambios sin guardar',
				text: 'Si cierras la ventana se perderán los cambios.',
				cancelButtonText: 'Cancelar',
				showCancelButton: true,
				confirmButtonText: 'Cerrar',
				focusConfirm: false,
				focusCancel: false,
				reverseButtons: true,
				buttonsStyling: false,
				customClass: {
					confirmButton: 'btn-prim-dark btn',
					cancelButton: 'btn-prim-light btn',
				},
			}).then((result) => {
				if (result && result.isConfirmed) toggleModalBar();
			});
		} else {
			toggleModalBar();
		}
	};

	return (
		<>
			<LateralModal
				title={
					modalData && modalData.id
						? `${modalData.nombre} ${
								modalData.apellidos ? modalData.apellidos : ''
						  }`
						: 'Nuevo contacto'
				}
				isOpen={modalBarOpen}
				toggleModal={closeModalMenu}
			>
				{modalEdit && modalData ? (
					<>
						<Formik
							innerRef={formikRef}
							enableReinitialize
							initialValues={
								modalData && modalData.id ? modalData : defModelForm
							}
							validationSchema={validationSchema}
							validateOnChange={false}
							validateOnBlur={false}
							onSubmit={(values, actions) => handleSubmitForm(values, actions)}
						>
							{({ values, setFieldValue, isSubmitting, resetForm }) => {
								const moreMails = values.esPersona;
								return (
									<>
										<Form autoComplete="off">
											<ModalBody>
												<div className="contact-edit">
													<div className="contact-form">
														{!singleContact && (
															<Row>
																<Col>
																	<ButtonGroup className="buttons-groups mb-3">
																		<Button
																			className={`btn-sec-light${
																				values.esPersona ? ' active' : ''
																			}`}
																			onClick={() =>
																				resetForm({
																					values: {
																						...defModelForm,
																						esPersona: true,
																					},
																				})
																			}
																			disabled={values.id ? true : false}
																		>
																			Persona
																		</Button>
																		<Button
																			className={`btn-sec-light${
																				!values.esPersona ? ' active' : ''
																			}`}
																			onClick={() =>
																				resetForm({
																					values: {
																						...defModelForm,
																						esPersona: false,
																					},
																				})
																			}
																			disabled={values.id ? true : false}
																		>
																			Productora
																		</Button>
																	</ButtonGroup>
																</Col>
															</Row>
														)}

														<Row>
															{values.esPersona ? (
																<>
																	<Col md="12">
																		<Field
																			label="Nombre*"
																			placeholder="Nombre*"
																			name="nombre"
																			component={InputForm}
																		/>
																	</Col>
																	<Col md="12">
																		<Field
																			label="Apellidos"
																			placeholder="Apellidos"
																			name="apellidos"
																			component={InputForm}
																		/>
																	</Col>
																	<Col md="12">
																		<Field
																			label="Empresa"
																			placeholder="Empresa"
																			name="empresa"
																			className="input-create w-arrow"
																			options={companies}
																			component={InputSelect}
																			createLabel={'Crear empresa'}
																			isClearable
																			canCreate
																		/>
																	</Col>
																	<Col md="12">
																		<Field
																			label="Cargo"
																			placeholder="Cargo"
																			name="cargo"
																			component={InputForm}
																		/>
																	</Col>
																	<Col md="12">
																		<Field
																			label="Etiqueta/s"
																			placeholder="Etiqueta/s"
																			name="etiquetasContactos"
																			className="input-create-select"
																			component={InputSticker}
																			options={tagList}
																			esProductora={false}
																		/>
																	</Col>
																	<Col md="12">
																		<Field
																			label="Teléfono"
																			placeholder="Teléfono"
																			name="telefono"
																			component={InputForm}
																			maxLength="9"
																			noSpace
																		/>
																	</Col>
																</>
															) : (
																<>
																	<Col md="12">
																		<Field
																			label="Nombre*"
																			placeholder="Nombre*"
																			name="nombre"
																			component={InputForm}
																		/>
																	</Col>

																	<Col md="12">
																		<Field
																			label="Etiqueta/s"
																			placeholder="Etiqueta/s"
																			name="etiquetasContactos"
																			className="input-create-select"
																			component={InputSticker}
																			options={tagList}
																			esProductora={true}
																		/>
																	</Col>
																</>
															)}

															<FieldArray
																name="emails"
																render={(arrayHelpers) => (
																	<div>
																		{values.emails.length > 0 &&
																			values.emails.map((paramList, index) => (
																				<div
																					key={index}
																					className="input-delete"
																				>
																					<Field
																						component={InputForm}
																						name={`emails.${index}.mail`}
																						placeholder="Email*"
																						label="Email*"
																					/>

																					{index !== 0 && (
																						<Button
																							onClick={() => {
																								arrayHelpers.remove(index);
																								values.id &&
																									deleteMailHelper(
																										paramList,
																										values.deletedMails,
																										setFieldValue
																									);
																							}}
																							type="button"
																							className="btn-sec-link btn-icon"
																						>
																							<i className="fticon-close"></i>
																						</Button>
																					)}
																				</div>
																			))}
																		{moreMails && (
																			<Col md="12" className="text-end">
																				<Button
																					type="button"
																					className="btn-prim-light mt-1"
																					onClick={() =>
																						arrayHelpers.push({ mail: '' })
																					}
																				>
																					Añadir otro email
																				</Button>
																			</Col>
																		)}
																	</div>
																)}
															/>
														</Row>
													</div>
												</div>
											</ModalBody>
											<ModalFooter>
												<Button
													type="submit"
													onClick={() => setFieldValue('reload', false)}
													className={
														modalData.id ? 'btn-sec-dark' : 'btn-sec-light'
													}
													disabled={isSubmitting}
												>
													Guardar
												</Button>
												{!modalData.id && (
													<Button
														type="submit"
														onClick={() => setFieldValue('reload', true)}
														className="btn-sec-dark"
														disabled={isSubmitting}
													>
														Guardar y nuevo
													</Button>
												)}
											</ModalFooter>
										</Form>
									</>
								);
							}}
						</Formik>
					</>
				) : (
					<>
						<ModalBody>
							<div className="contact-read">
								<WhiteBox>
									<Medal
										text={`${modalData.nombre} ${
											modalData.apellidos ? modalData.apellidos : ''
										}`}
										color={
											modalData && modalData.color
												? modalData.color.claseCss
												: 'blue'
										}
									/>
									<h3>{`${modalData.nombre} ${
										modalData.apellidos ? modalData.apellidos : ''
									}`}</h3>
									<div className="contact-companydetails">
										{modalData.empresa && <p>{modalData.empresa.label}</p>}
										{modalData.cargo && <p>{modalData.cargo}</p>}
									</div>
									{modalData.etiquetasContactos &&
										modalData.etiquetasContactos.length > 0 &&
										modalData.etiquetasContactos.map((etq, index) => (
											<Sticker
												key={index}
												text={etq.etiqueta.label}
												color={etq.etiqueta.color.claseCss}
											/>
										))}
									<div className="contact-details">
										{modalData.telefono && <p>{modalData.telefono}</p>}
										{modalData.emails &&
											modalData.emails.length > 0 &&
											modalData.emails.map((eml, index) => (
												<p key={index}>{eml.mail}</p>
											))}
									</div>
								</WhiteBox>
							</div>
						</ModalBody>
						<ModalFooter>
							<Button
								className="btn-sec-dark"
								onClick={() => toggleModalEdit(true)}
							>
								Editar
							</Button>
						</ModalFooter>
					</>
				)}
			</LateralModal>
		</>
	);
};

export default ContactsForm;
