import React from 'react';

const LoadingLayer = ({ visible, hideAll = false }) => {
	return (
		visible && (
			<div className={`overlay upper ${hideAll ? 'full' : ''}`}>
				<div className="custom-loader"></div>
			</div>
		)
	);
};

export default LoadingLayer;
