import React from 'react';
import _ from 'lodash';

//Controls
import Select, { createFilter, components } from 'react-select';
import CreatableSelect from 'react-select/creatable';

const {
	ValueContainer,
	Placeholder,
	DropdownIndicator,
	ClearIndicator,
	MultiValueRemove,
	Option,
} = components;

const CustomValueContainer = ({ children, ...props }) => {
	return (
		<ValueContainer {...props}>
			<Placeholder {...props} isFocused={props.isFocused}>
				{props.selectProps.placeholder}
			</Placeholder>
			{React.Children.map(children, (child) =>
				child && child.type !== Placeholder ? child : null
			)}
		</ValueContainer>
	);
};

const CustomMultiValueContainer = ({ children, hasValue, getValue, ...props }) => {

	if (!hasValue) {
		return (
		  <ValueContainer {...props}>
		  <Placeholder {...props} isFocused={props.isFocused}>
					{props.selectProps.placeholder}
				</Placeholder>
				{React.Children.map(children, (child) =>
				child && child.type !== Placeholder ? child : null
			)}
		  </ValueContainer>
		);
	 } else {

		var valueLength = getValue().length;
		return (
			<ValueContainer {...props} className={hasValue ? 'w-value':''}>
				<Placeholder {...props} isFocused={props.isFocused}>
					{props.selectProps.placeholder}
				</Placeholder>
				{valueLength >= 2 && (
					<span className="selecteds">
						{!props.selectProps.inputValue &&
							`${valueLength} seleccionada${valueLength !== 1 ? 's' : ''}`}
					</span>
				)}
				{React.Children.map(children, (child) => {
					return child.type !== components.Input &&
						valueLength < 2 &&
						child.type !== Placeholder
						? child
						: null;
				})}
			</ValueContainer>
		);

	 }

	
};

const CustomDropdown = (props) => {
	return (
		<DropdownIndicator {...props}>
			<i className="fticon-arrow-down"></i>
		</DropdownIndicator>
	);
};

const CustomClear = (props) => {
	return (
		<ClearIndicator {...props}>
			<i className="fticon-close"></i>
		</ClearIndicator>
	);
};

const CustomMultiRemove = (props) => {
	return (
		<MultiValueRemove {...props}>
			<i className="fticon-close"></i>
		</MultiValueRemove>
	);
};

const SelectableOption = (props) => {
	return (
		<Option {...props}>
			<input
				type="checkbox"
				className="base-checkbox"
				checked={props.isSelected}
				onChange={() => null}
			/>{' '}
			<label>{props.label}</label>
		</Option>
	);
};

const CustomOption = (props) => {
	return (
		<Option {...props}>
			<span>{props.label}</span>
		</Option>
	);
};

const customStyles = {
	container: (provided, state) => ({
		...provided,
	}),

	valueContainer: (provided, state) => ({
		...provided,
		overflow: 'visible',
		paddingTop: (state.isFocused || state.hasValue || state.selectProps.inputValue) && 14,
	}),

	placeholder: (provided, state) => ({
		...provided,
		position: 'absolute',
		top: state.isFocused || state.hasValue || state.selectProps.inputValue ? -14 : 0,
		transition: 'top 0.1s, font-size 0.1s',
		fontSize: (state.isFocused || state.hasValue|| state.selectProps.inputValue) && 11,
	}),
};

const InputSelect = ({
	label,
	options,
	components,
	//Custom
	canCreate,
	noForm,
	selectables,
	grouped,
	noHideOpts,
	plainValue,
	findByValue,
	canSearch,
	//Fields
	help,
	createLabel,
	regExp,
	//Formik
	field,
	form,
	...props
}) => {
	const onChange = (e) => {
		if (noForm) props.onChange(e);
		else {
			form.setFieldValue(field.name, e);
			form.setFieldTouched(field.name, true);
		}
	};

	const error = field ? _.get(form.errors, field.name) : null;

	const loadComponents = {
		ValueContainer: grouped ? CustomMultiValueContainer : CustomValueContainer,
		DropdownIndicator: CustomDropdown,
		ClearIndicator: CustomClear,
		MultiValueRemove: CustomMultiRemove,
		Option: selectables ? SelectableOption : CustomOption,
	};

	return (
		<>
			{canCreate ? (
				<CreatableSelect
					className={`${grouped ? 'select-grouped' : 'select-ungrouped'} ${
						error ? 'select-has-error' : ''
					}`}
					classNamePrefix="input-select"
					noOptionsMessage={() => help || 'No hay opciones'}
					formatCreateLabel={(inputValue) =>
						`${createLabel || 'Crear etiqueta'}: ${inputValue}`
					}
					value={noForm ? props.value : field.value}
					options={options}
					onChange={onChange}
					components={loadComponents}
					closeMenuOnSelect={selectables ? false : true}
					hideSelectedOptions={selectables ? false : true}
					styles={customStyles}
					isDisabled={props.disabled}
					{...props}
				/>
			) : (
				<Select
					className={`${grouped ? 'select-grouped' : 'select-ungrouped'} ${
						error ? 'select-has-error' : ''
					}`}
					classNamePrefix="input-select"
					filterOption={createFilter({ ignoreAccents: false })}
					noOptionsMessage={() => help || 'No hay opciones'}
					placeholder={label}
					value={
						noForm
							? props.value
							: options && field.value
							? plainValue
								? options.find((option) => _.isEqual(option.value, field.value))
								: findByValue ? options.find((option) => _.isEqual(option.value, field.value.value)) : options.find((option) => _.isEqual(option, field.value))
							: null
					}
					onChange={onChange}
					options={options}
					isSearchable={canSearch || false}
					components={loadComponents}
					closeMenuOnSelect={selectables ? true : true}
					hideSelectedOptions={selectables ? false : noHideOpts ? false : true}
					styles={customStyles}
					isDisabled={props.disabled}
					{...props}
				/>
			)}
		</>
	);
};

InputSelect.propTypes = {};
export default InputSelect;
