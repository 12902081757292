import React, { useEffect, useState } from 'react';

//forms
import { Formik, Form, Field, useFormikContext } from 'formik';

//Components
import { Col, Row } from 'reactstrap';
import InputForm from '../forms/InputForm';
import Popup from 'reactjs-popup';

const SeatEditPlano = ({
	seatDetails,
	isEditingSections,
	updateSeatFn,
	updateSeatTypeFn,
	cursorIsHand,
	areaIx,
	rowIx,
	seatIx,
}) => {
	const [tempValues, setTempValues] = useState({});

	const AutoSaveSeatValues = () => {
		const { values, dirty } = useFormikContext();
		useEffect(() => {
			dirty && setTempValues(values);
		}, [values, dirty]);
		return null;
	};

	return !isEditingSections ? (
		cursorIsHand ? (
			<div
				id={`btc-id-${seatDetails.id}`}
				className={`row-seat ${seatDetails.type} ${
					seatDetails.section ? seatDetails.section.type : ''
				}`}
			>
				<span>
					{seatDetails.type !== 'space' && seatDetails.text && seatDetails.text}
				</span>
			</div>
		) : seatDetails.type === 'space' ? (
			<div
				id={`btc-id-${seatDetails.id}`}
				className={`row-seat ${seatDetails.type} ${
					seatDetails.section ? seatDetails.section.type : ''
				}`}
			>
				<span></span>
			</div>
		) : (
			<Popup
				trigger={(open) => (
					<div
						id={`btc-id-${seatDetails.id}`}
						className={`row-seat ${seatDetails.type} ${
							seatDetails.section ? seatDetails.section.type : ''
						}`}
					>
						<span>{seatDetails.text && seatDetails.text}</span>
					</div>
				)}
				position="top center"
				closeOnDocumentClick
				onClose={() =>
					updateSeatFn({
						...tempValues,
						areaIndex: areaIx,
						rowIndex: rowIx,
						seatIndex: seatIx,
					})
				}
			>
				<Formik initialValues={seatDetails}>
					{({ setFieldValue, values }) => (
						<>
							<Form autoComplete="off">
								<div className="seat-form">
									<Row>
										<Col md="12">
											<Field
												label="Número de asiento"
												placeholder="Número de asientoto"
												name="text"
												min={0}
												type="number"
												component={InputForm}
											/>
										</Col>
									</Row>
								</div>
							</Form>
							<AutoSaveSeatValues />
						</>
					)}
				</Formik>
			</Popup>
		)
	) : (
		<div
			id={`btc-id-${seatDetails.id}`}
			className={`row-seat seat-select ${seatDetails.type} ${
				seatDetails.section && seatDetails.section.type
					? seatDetails.section.type
					: ''
			}`}
			onClick={() => updateSeatTypeFn(seatDetails, areaIx, rowIx, seatIx)}
		>
			<span>
				{seatDetails.type === 'seat' && seatDetails.text && seatDetails.text}
			</span>
		</div>
	);
};

export default SeatEditPlano;
