import { callApiGet, callApiPost } from './apiUtils';
const url_base = '/mapas';


export const getMapList = async (filters) => {
	return await callApiPost(`${url_base}/getMaps`, filters);
};

export const deleteMap = async (id) => {
	return await callApiPost(`${url_base}/deleteMap/${id}`, id);
};

export const setMap = async (mapData) => {
	return await callApiPost(`${url_base}/setMap`, mapData);
};

export const setDetailMap = async (mapData) => {
	return await callApiPost(`${url_base}/setDetailMap`, mapData);
};

export const getTags = async () => {
	return await callApiGet(`${url_base}/getTags`);
};

export const saveBasicMap = async (mapData) => {
	return await callApiPost(`${url_base}/saveBasicMap`, mapData);
};

//---------- editor

export const getAreasType = async () => {
	return await callApiGet(`${url_base}/getAreaTypes`);
};

export const getSectionsType = async () => {
	return await callApiGet(`${url_base}/getSections`);
};

export const getMap = async (id) => {
	return await callApiGet(`${url_base}/getDetailMap/${id}`);
};

export const createArea = async (areaData) => {
	return await callApiPost(`${url_base}/createArea`, areaData);
};

export const deleteArea = async (areaID) => {
	return await callApiPost(`${url_base}/deleteArea/${areaID}`, areaID);
};

export const moveArea = async (areaData) => {
	return await callApiPost(`${url_base}/moveArea`, areaData);
};

export const updateArea = async (areaData) => {
	return await callApiPost(`${url_base}/updateArea`, areaData);
};

export const updateTextSeats = async (seatsData) => {
	return await callApiPost(`${url_base}/updateTextSeats`, seatsData);
};

export const getErroresMapa = async (mapId) => {
	return await callApiGet(`${url_base}/getErroresMapa/${mapId}`);
};

export const updateSectionSeats = async (seatsData) => {
	return await callApiPost(`${url_base}/updateSectionSeats`, seatsData);
};

export const updateBulkFilaColumna = async (seatsData) => {
	return await callApiPost(`${url_base}/updateBulkFilaColumna`, seatsData);
};

